import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import axios from 'axios';
import update from 'immutability-helper';
import { Helmet } from 'react-helmet';

import ContentPopup from './common/ContentPopup';
import ManageCardView from './ManageCardView';
import AuthPopup from './auth/AuthPopup';

import Storage from '../helpers/Storage';
import { triggerEvent } from '../helpers/global.js';
import { sendRequest } from '../helpers/RequestDispatcher.js';

import '../sass/components/SubscribeView.scss';

const SUBSCRIPTIONS = [
  {
    subscription_type: 'Free',
    account_type: 'everyone',
    price: 'Free',
    list: [
      '<u>First 10 chat</u> messages free',
      '<u>Limited access</u> to these directories:',
      [
        'Partner',
        'Join an Organisation',
        'Hiring',
        'Mentor or Advise',
        'Raise funding',
        'Invest',
        'Mergers & Acquisition',
      ],
    ],
  },
  {
    subscription_type: 'Pro',
    account_type: 'user',
    paid: true,
    list: [
      '<u>Unlimited</u> chat messages',
      '<u>Full access</u> to these directories:',
      ['Partner', 'Join an Organisation', 'Hiring', 'Mentor or Advise'],
    ],
  },
  {
    subscription_type: 'Pro',
    account_type: 'business_profile',
    paid: true,
    list: [
      '<u>Unlimited</u> chat messages',
      '<u>Full access</u> to these directories:',
      [
        'Partner',
        'Join an Organisation',
        'Hiring',
        'Mentor or Advise',
        'Raise funding',
        'Invest',
        'Mergers & Acquisition',
      ],
    ],
  },
];

const mapStoreToProps = (store) => ({
  user: store.data.user,
  businessProfile: store.data.businessProfile,
});

const PRODUCT_PREFIX = 'sdgco';

class SubscribeView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prices: [],
      duration: 'monthly',
      showCardForm: false,
      stripePrice: null,
    };
  }

  componentDidMount = () => {
    // axios is used instead of sendRequest to make easier the Authorization header passing.
    axios({
      url: 'https://api.stripe.com/v1/prices',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRIPE_API_SECRET_KEY}`,
      },
    }).then((response) => {
      if (response.data && response.data.data) {
        this.setState({ prices: response.data.data });
      }
    });
  };

  componentDidUpdate = (prevProps, prevState) => {};

  sendSubscription = (stripePrice) => {
    const method = this.props.businessProfile
      ? `subscriptions/business_profile/${this.props.businessProfile?.id}`
      : 'subscriptions';

    sendRequest({
      method: method,
      type: 'POST',
      data: {
        price_id: stripePrice?.id,
      },
      success: (data) => {
        if (this.props.businessProfile) {
          Storage.setData(
            'businessProfile',
            update(this.props.businessProfile, {
              subscribed: { $set: true },
            })
          );
        } else {
          Storage.setData(
            'user',
            update(this.props.user, {
              subscribed: { $set: true },
            })
          );
        }
        triggerEvent('showSnackbar', [
          { text: 'Successfully subscribed.', type: 'success' },
        ]);
      },
      error: (data) => {
        if (data.error_message) {
          triggerEvent('showSnackbar', [
            { text: data.error_message, type: 'error' },
          ]);
        } else {
          triggerEvent('showSnackbar', [
            { text: 'Failed to subscribe', type: 'error' },
          ]);
        }
      },
    });
  };

  subscribe = (stripePrice) => {
    if (!this.props.user) {
      triggerEvent('showContentPopup', [
        {
          content: (
            <AuthPopup
              key={Date.now()}
              onLogin={() => triggerEvent('hideContentPopup')}
            />
          ),
        },
      ]);
    } else if (
      this.props.businessProfile &&
      this.props.businessProfile.has_card
    ) {
      this.sendSubscription(stripePrice);
    } else if (!this.props.businessProfile && this.props.user.has_card) {
      this.sendSubscription(stripePrice);
    } else {
      this.setState({ showCardForm: true, stripePrice: stripePrice });
    }
  };

  checkSubscriptionDisable = (item) => {
    if (!this.props.businessProfile && !this.props.user) {
      return false;
    }
    const accountType = this.props.businessProfile
      ? 'business_profile'
      : 'user';
    return accountType !== item.account_type;
  };

  renderDurationInput = () => {
    const { duration } = this.state;
    return (
      <div
        className="durationInput"
        onClick={() => this.handleDurationChange(duration)}
      >
        <div className="switcher">
          <div
            className={classnames({
              durationOption: true,
              activeDurationOption: duration === 'monthly',
            })}
          >
            <span>Monthly</span>
          </div>
          <div
            className={classnames({
              durationOption: true,
              activeDurationOption: duration === 'yearly',
            })}
          >
            <span>Yearly</span>
          </div>
        </div>
      </div>
    );
  };

  renderSubscriptionCapabilities = (item) => {
    return (
      <ul
        className={classnames({
          checkmarkList: true,
          paidCheckmarkList: item.paid,
        })}
      >
        {item.list.map((li, index) => (
          <div key={index}>
            {typeof li === 'object' ? (
              <ul className="subList">
                {li.map((sub, i) => (
                  <li key={i}>{sub}</li>
                ))}
              </ul>
            ) : (
              <li dangerouslySetInnerHTML={{ __html: li }}></li>
            )}
          </div>
        ))}
      </ul>
    );
  };

  itemPrice = (stripePrice) => {
    const price = stripePrice ? stripePrice.unit_amount / 100 : 0;
    return price;
  };

  renderItemPrice = (stripePrice, payment) => {
    const price = this.itemPrice(stripePrice);
    const period = this.state.duration === 'yearly' ? 'year' : 'month';
    if (payment) {
      return `$${price}/${period}`;
    } else {
      return `US$${price}/${period}`;
    }
  };

  renderSecondaryPrice = (stripePrice) => {
    const price = this.itemPrice(stripePrice);
    const secondaryPeriod = this.state.duration === 'yearly' ? 'month' : 'year';
    const secondaryPrice =
      this.state.duration === 'yearly' ? price / 12 : price * 12;
    return `US$${secondaryPrice.toFixed(2)}/${secondaryPeriod}`;
  };

  renderSubscriptionCard = (item, key) => {
    const itemPrice = this.state.prices.filter(
      (price) =>
        price.product ===
        `${PRODUCT_PREFIX}_${item.account_type}_${this.state.duration}`
    )[0];
    const profile = this.props.businessProfile
      ? this.props.businessProfile
      : this.props.user;
    return (
      <div className="subscriptionItem" key={key}>
        <div
          className={classnames({
            subscriptionHeader: true,
            paidHeader: item.paid,
          })}
        >
          <div className="subscriptionType">{item.subscription_type}</div>
          <div className="accountType">
            for&nbsp;
            <span>
              {item.account_type === 'user'
                ? 'individuals'
                : item.account_type === 'business_profile'
                ? 'organisations'
                : 'everyone'}
            </span>
          </div>
        </div>
        <div className="price">
          {item.paid ? (
            <>
              {this.renderItemPrice(itemPrice, false)}
              <div className="secondaryPrice">
                {this.renderSecondaryPrice(itemPrice)}
              </div>
            </>
          ) : (
            'Free'
          )}
        </div>
        {this.renderSubscriptionCapabilities(item)}
        {item.paid ? (
          <div
            className={classnames({
              subscribe: true,
              disabled: this.checkSubscriptionDisable(item),
            })}
          >
            {profile?.subscribed ? (
              <div className="alreadySubscribed">
                You are already subscribed
              </div>
            ) : (
              <button
                onClick={() => this.subscribe(itemPrice)}
                disabled={this.checkSubscriptionDisable(item)}
              >
                Upgrade now
              </button>
            )}
          </div>
        ) : null}
      </div>
    );
  };

  handleDurationChange = (value) => {
    const duration = value === 'yearly' ? 'monthly' : 'yearly';
    this.setState({ duration: duration });
  };

  addCardCallback = () => {
    this.setState({ showCardForm: false });
    this.sendSubscription(this.state.stripePrice);
  };

  render = () => {
    const { stripePrice } = this.state;
    return (
      <div className="subscribeView">
        <Helmet>
          <title>The SDG Co</title>
          <meta name="description" content="The SDG Co" />
          <meta name="keywords" content="social" />
          <meta property="og:url" content="https://thesdg.co" />
          <meta property="og:site_name" content="The SDG Co" />
          <meta property="og:title" content="The SDG Co" />
          <meta property="og:image" content="https://thesdg.co/logo.png" />
          <meta property="og:description" content="The SDG Co" />
          <meta property="og:type" content="website" />
          <meta name="image" content="https://thesdg.co/logo.png" />
        </Helmet>
        <div className="header">
          <div className="mainText">
            Grow & nurture your SDG network with Pro
          </div>
          <div className="secondaryHeader">
            Get connected with the SDG network and full access to a directory of
            who’s who.
          </div>
        </div>
        <div className="duration">{this.renderDurationInput()}</div>
        <div className="subscriptionContainer">
          {SUBSCRIPTIONS.map((subscription, i) =>
            this.renderSubscriptionCard(subscription, i)
          )}
        </div>
        <ContentPopup
          show={this.state.showCardForm}
          title="Upgrade to Pro"
          content={
            <>
              <div className="subscriptionInfo">
                <div className="subscriptionPrice">
                  {this.renderItemPrice(stripePrice, true)}
                </div>
                <div className="subscriptionPlan">
                  {this.state.duration} Plan
                </div>
              </div>
              <ManageCardView
                embedded
                price={stripePrice}
                onPay={this.addCardCallback}
                buttonText="Make Payment"
              />
            </>
          }
          callback={() => this.setState({ showCardForm: false })}
        />
      </div>
    );
  };
}

export default connect(mapStoreToProps)(SubscribeView);
