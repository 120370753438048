import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import TabsView from '../components/common/TabsView';
import GoalCard from './common/GoalCard';
import Avatar from './common/Avatar';
import Post from './feed/Post';
import ContentPlaceholder from './common/ContentPlaceholder';

import { sendRequest } from '../helpers/RequestDispatcher.js';
import { triggerEvent } from '../helpers/global.js';

import '../sass/components/ProfileView.scss';

const TABS = [
  {
    id: 1,
    title: 'About',
    key: 'about',
  },
  {
    id: 2,
    title: 'Posts',
    key: 'posts',
  },
];

const INIT_DATA = [
  { url: 'common_data/goals', object: 'goals' },
  { url: 'common_data/objectives', object: 'objectives' },
  { url: 'common_data/categories', object: 'categories' },
  { url: 'common_data/subcategories', object: 'subcategories' },
];

const ITEMS_PER_LOAD = 5;

const mapStoreToProps = (store) => ({
  user: store.data.user,
  businessProfile: store.data.businessProfile,
});

class ProfileView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      activeTab: TABS[0].key,
      goals: [],
      objectives: [],
      categories: [],
      subcategories: [],
      solutions: [],
      posts: [],
      loadNumber: 0,
      noMore: false,
      isOrganization: false,
      profileLoaded: false,
      postsLoaded: false,
      isOwnProfile: false,
    };
  }

  componentDidMount = () => {
    this.initData();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.initData();
    }
  }

  initData = () => {
    INIT_DATA.forEach((item) =>
      this.getData(item.url, item.object, item.data, item.callback)
    );
    if (this.props.match.path === '/profile') {
      this.setState({ data: this.props.user });
      this.afterProfileLoad();
    } else if (this.props.match.path.includes('user')) {
      this.getData(
        `users/${this.props.match.params.identifier}`,
        'data',
        null,
        () => this.afterProfileLoad()
      );
    } else {
      this.getData(
        `business_profiles/${this.props.match.params.identifier}`,
        'data',
        null,
        () => this.setState({ profileLoaded: true })
      );
      this.getData(`solutions`, 'solutions', {
        profile_id: this.props.match.params.identifier,
      });
      this.setState({ isOrganization: true });
    }
  };

  getPostsRequestData = () => {
    if (this.props.user && this.props.match.path === '/profile') {
      return { user_id: this.props.user.id };
    } else if (this.props.match.path.includes('user')) {
      return { user_id: this.state.data.id };
    } else {
      return { business_profile_id: this.props.match.params.identifier };
    }
  };

  getData = (url, object, data = null, callback = null) => {
    if (data === 'getPostsRequestData') data = this.getPostsRequestData();
    sendRequest({
      method: url,
      data,
      type: 'GET',
      success: (data) => {
        this.setState({ [object]: data });
        if (callback) {
          callback(this, data);
        }
      },
      error: (data) => {},
    });
  };

  afterProfileLoad = () => {
    this.setState({
      profileLoaded: true,
      isOwnProfile: this.checkProfileOwner(),
    });
    this.getData(`posts`, 'posts', 'getPostsRequestData', (ctx, data) =>
      this.afterPostRequest(data)
    );
  };

  afterPostRequest = (data) => {
    if (data.length < ITEMS_PER_LOAD) {
      this.setState({
        noMore: true,
        postsLoaded: true,
      });
    } else {
      this.setState((prevState) => ({
        loadNumber: prevState.loadNumber + 1,
        noMore: data.length < ITEMS_PER_LOAD,
        postsLoaded: true,
      }));
    }
  };

  paramRole = () => {
    if (this.props.match.path.includes('user')) {
      return 'individual';
    } else {
      return 'organization';
    }
  };

  changeTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  checkProfileOwner = () => {
    if (this.props.user && this.props.match.path === '/profile') {
      return true;
    } else if (this.props.match.path.includes('user')) {
      return this.props.user && this.state.data.id === this.props.user.id;
    } else {
      return this.state.data.user_id === this.props.user.id;
    }
  };

  handleSocialLinkClick = (url) => {
    const link = url.indexOf('http') === 0 ? url : `https://${url}`;
    window.open(link, '_blank');
  };

  handleEditProfileClick = () => {
    const userId = this.props.user.slug || this.props.user.id;
    if (this.props.match.path === '/profile') {
      this.props.history.push(`/user/${userId}/edit`);
    } else if (this.props.match.path.includes('user')) {
      this.props.history.push(`/user/${userId}/edit`);
    } else {
      this.props.history.push(`/business/${this.state.data.id}/edit`);
    }
  };

  handleGoalClick = (key) => {
    this.props.history.push(`/feed/${key}`);
  };

  loadMore = () => {
    const data = this.getPostsRequestData();
    data.offset = this.state.loadNumber * ITEMS_PER_LOAD;
    data.limit = ITEMS_PER_LOAD;
    sendRequest({
      type: 'GET',
      method: 'posts',
      data,
      success: (data) => {
        if (data.length === 0) {
          triggerEvent('showSnackbar', [{ text: 'Sorry, no more posts.' }]);
          this.setState({
            noMore: true,
          });
        } else {
          this.setState((prevState) => ({
            posts: prevState.posts.concat(data),
            loadNumber: prevState.loadNumber + 1,
            noMore: data.length < ITEMS_PER_LOAD,
          }));
        }
      },
      error: (data) => {},
    });
  };

  ownProfile = () => {
    const { businessProfile, user } = this.props;
    return businessProfile ? businessProfile : user;
  };

  connectToChat = () => {
    if (!this.props.user) {
      triggerEvent('showSnackbar', [
        { text: 'Please, sign up or log in first.' },
      ]);
      return;
    }
    const profile_data = this.state.data;
    const partnerType = profile_data.chat_id
      ? 'business_profile_id'
      : 'user_id';
    const data = {
      partner: {
        [partnerType]: profile_data.id,
      },
    };
    if (this.props.businessProfile) {
      data['business_profile_id'] = this.props.businessProfile.id;
    }

    sendRequest({
      type: 'POST',
      method: 'chats',
      data,
      success: (data) => {
        this.props.history.push(`/chats/${data.id}`);
      },
      error: (data) => {},
    });
  };

  claimRemoveProfile = (key) => {
    this.props.history.push({
      pathname: '/claim-profiles',
      state: {businessProfile: key, company_url: window.location.href}
    });
  };
  renderGoal = (item) => {
    return (
      <div
        key={item.key}
        className="goalCell"
        style={{
          borderColor: item.color,
        }}
      >
        <div className="goalCellContent">
          <label>{item.title}</label>
          <div className={`goalImage ${item.key}`} />
        </div>
      </div>
    );
  };

  renderSolution = (item) => {
    const { categories, subcategories } = this.state;
    return (
      <div key={item.id} className="solutionItem">
        <div className="solutionTags">
          <span>
            {
              categories.find((category) => category.id === item.category_id)
                ?.name
            }
          </span>
          <span className="spacer">&bull;</span>
          <span>
            {
              subcategories.find(
                (subcategory) => subcategory.id === item.subcategory_id
              )?.name
            }
          </span>
        </div>
        <div className="solutionName">{item.name}</div>
        <div className="solutionDescription">{item.description}</div>
      </div>
    );
  };

  renderAbout = () => {
    const { data, isOrganization, solutions, profileLoaded } = this.state;
    return (
      <>
        {profileLoaded ? (
          <div
            className="about"
            dangerouslySetInnerHTML={{
              __html: `<p>${data.bio || data.description}</p>`,
            }}
          />
        ) : (
          <ContentPlaceholder type="profileAbout" />
        )}
        <div className="interestedTitle title">Interested UN SDGs</div>
        {profileLoaded ? (
          <div className="goalsTable">
            {data && data.goal_ids
              ? data.goal_ids.map((id) => {
                const item = this.state.goals.find((i) => i.id === id);
                if (!!item)
                  return (
                    <GoalCard
                      key={item.key}
                      item={item}
                      active
                      onClick={() => this.handleGoalClick(item.key)}
                    />
                  );
              })
              : null}
          </div>
        ) : (
          <ContentPlaceholder type="profileGoals" />
        )}
        {profileLoaded && isOrganization && solutions.length > 0 ? (
          <div className="solutionsContainer">
            <div className="solutionsTitle title">Solutions provided</div>
            {solutions.map(this.renderSolution)}
          </div>
        ) : null}
      </>
    );
  };

  renderPosts = () => {
    return (
      <div className="postsTimeline">
        {this.state.posts &&
          this.state.posts.map((post) => (
            <Post
              post={post}
              user={this.props.user}
              author={post.author}
              likeCount={post.likes_count}
              publishDateTime={post.created_at}
              article={post.author_title}
              isLiked={post.liked}
              key={post.id}
            />
          ))}
        {this.state.noMore ? null : (
          <div className="loadMore" onClick={this.loadMore}>
            <p>Load more posts</p>
          </div>
        )}
      </div>
    );
  };

  renderContacts = () => {
    const profile = this.state.data;
    return (
      <div className="contacts">
        {profile.website ? (
          <div
            className="web"
            onClick={() => this.handleSocialLinkClick(profile.website)}
          >
            <p>Visit website</p>
          </div>
        ) : null}
        {profile.twitter_url ? (
          <div
            className="twitter"
            onClick={() =>
              this.handleSocialLinkClick(
                `https://twitter.com/${profile.twitter_url}`
              )
            }
          >
            <p>{profile.twitter_url}</p>
          </div>
        ) : null}
        {profile.linkedin_url ? (
          <div
            className="linkedin"
            onClick={() => this.handleSocialLinkClick(profile.linkedin_url)}
          >
            <p>Visit LinkedIn</p>
          </div>
        ) : null}
        {profile.facebook_url ? (
          <div
            className="facebook"
            onClick={() =>
              this.handleSocialLinkClick(
                `https://facebook.com/${profile.facebook_url}`
              )
            }
          >
            <p>{profile.facebook_url}</p>
          </div>
        ) : null}
        {profile.instagram_url ? (
          <div
            className="instagram"
            onClick={() =>
              this.handleSocialLinkClick(
                `https://instagram.com/${profile.instagram_url}`
              )
            }
          >
            <p>{profile.instagram_url}</p>
          </div>
        ) : null}
      </div>
    );
  };

  renderContactsPlaceholder = () => {
    return (
      <div className="contacts">
        <div className="web">
          <ContentPlaceholder type="profileContact" />
        </div>
        <div className="twitter">
          <ContentPlaceholder type="profileContact" />
        </div>
        <div className="linkedin">
          <ContentPlaceholder type="profileContact" />
        </div>
        <div className="facebook">
          <ContentPlaceholder type="profileContact" />
        </div>
        <div className="instagram">
          <ContentPlaceholder type="profileContact" />
        </div>
      </div>
    );
  };

  renderLookingToGoals = () => {
    const { data } = this.state;
    const objectives = this.props.businessProfile
      ? this.state.objectives.filter(
          (objective) =>
            ['any', 'organisation'].indexOf(objective.objective_type) > -1
        )
      : this.state.objectives.filter(
          (objective) =>
            ['any', 'individual'].indexOf(objective.objective_type) > -1
        );

    return data && data.objective_ids
      ? data.objective_ids.map((id) => {
          const item = objectives.find((i) => i.id === id);
          return (
            <>
              {item ? (
                <div className="connectItem" key={item.id}>
                  {item.title}
                </div>
              ) : null}
            </>
          );
        })
      : null;
  };

  renderLookingToProfileType = () => {
    const { data } = this.state;
    return data && data.profile_type
      ? 
        <div className="connect">
          <div className="blockTitle title">Profile Type</div>
            <div className='connectItem'>{data.profile_type} </div>
         </div>
            
      : null;
  };

  renderConnect = () => {
    const { isOwnProfile, profileLoaded } = this.state;
    return (
      <div className="connect">
        <div className="blockTitle title">Looking to...</div>
        {profileLoaded
          ? this.renderLookingToGoals()
          : [...Array(3)].map((e, i) => (
              <ContentPlaceholder key={i} type="profileLookingTo" />
            ))}
        {isOwnProfile ? null : (
          <>
          <button
            className="connectButton"

            onClick={() => this.connectToChat()}
          >
            Connect
          </button>
         </>
        )}
      </div>
    );
  };

  renderClaimRemoveLink = () => {
    const { userProfile, userprofile } = this.state.data;
    return this.props.location.pathname.includes("business") && !this.state?.data?.user_id
    ?
      <div className="claim_remove_link">
    
          <div className='link_div'>
          <a
           href=''
           className="claim_remove_btn"
           key={userProfile}
           onClick={() => this.claimRemoveProfile(this.state?.data)}
         >
          I'd like to claim / remove profile
         </a>
          </div>
      </div>
    : null;
  };


  renderProfile = () => {
    const profile = this.state.data;
    const activeTab = this.state.activeTab;
    const { isOwnProfile, profileLoaded, isProfileType } = this.state;

    return (
      <>
        {profile.cover_photo ? (
          <div
            className="profileBanner"
            style={{
              backgroundImage: `url(${profile.cover_photo})`,
            }}
          />
        ) : null}
        <div className="container">
          {profileLoaded ? (
            <div className="profileHeader">
              <Avatar
                imageUrl={profile.image}
                username={profile.name}
                className={
                  ('profileImage',
                  {
                    ownProfile: isOwnProfile,
                  })
                }
                big
              />
              <div className="profileNameLocation">
                <div className="profileName title">
                  {profile.name}
                  {profile.subscribed ? <div className="subscribed" /> : null}
                </div>
                {!this.state.isOrganization && profile.description ? (
                  <div className="profileSubtitle">{profile.description}</div>
                ) : null}
                {profile.country_name ? (
                  <div className="profileLocation">
                    {profile.country_name}
                    {profile.city ? `, ${profile.city}` : null}
                  </div>
                ) : null}
              </div>
              {isOwnProfile ? (
                <div
                  className="editProfileBtn"
                  onClick={this.handleEditProfileClick}
                >
                  Edit Profile
                </div>
              ) : null}
            </div>
          ) : (
            <ContentPlaceholder type="profileHeader" />
          )}
          <TabsView
            tabs={TABS}
            onTabChange={this.changeTab}
            tab={activeTab}
            leftAligned
          />
          <div className="flex">
          <div className="left">
            </div>
            <div className="right">
               {this.renderClaimRemoveLink()}
          </div>
          </div>
          <div className="flex">
            <div className="left">
              {activeTab === 'posts'
                ? profileLoaded
                  ? this.renderPosts()
                  : [...Array(2)].map((element, index) => (
                      <div key={index}>
                        <ContentPlaceholder type="post" />
                      </div>
                    ))
                : this.renderAbout()}
            </div>
            <div className="right">
              {profileLoaded
                ? this.renderContacts()
                : this.renderContactsPlaceholder()}
              {this.renderConnect()}
              <br></br>
              {this.renderLookingToProfileType()}
            </div>
          </div>
        </div>
      </>
    );
  };

  render = () => {
    return (
      <div className="profileView">
        <Helmet>
          <title>The SDG Co</title>
          <meta name="description" content="The SDG Co" />
          <meta name="keywords" content="social" />
          <meta property="og:url" content="https://thesdg.co" />
          <meta property="og:site_name" content="The SDG Co" />
          <meta property="og:title" content="The SDG Co" />
          <meta property="og:image" content="https://thesdg.co/thesdg.png" />
          <meta property="og:description" content="The SDG Co" />
          <meta property="og:type" content="website" />
          <meta name="image" content="https://thesdg.co/thesdg.png" />
          </Helmet>
        {this.renderProfile()}
      </div>
    );
  };
}

export default connect(mapStoreToProps)(withRouter(ProfileView));
