import React from 'react';
import classnames from 'classnames';

import FooterView from './FooterView';
import HeaderMeta from './common/HeaderMeta';

import Storage from '../helpers/Storage';

import '../sass/components/AboutView.scss';

class DirectoryAirtableView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      webpSupported: true,
    };
  }

  componentDidMount = () => {
    if (typeof window !== 'undefined') {
      this.setState({ webpSupported: Storage.getSetup('webpSupported') });
    }
  };

  render = () => {
    return (
      <div
        className={classnames('aboutView background', {
          webp: this.state.webpSupported,
        })}
      >
        <HeaderMeta />
        <div className="content">
          <div className="title">View Directory</div>
          <div className="section">
            <p>
              The resources outlined on this page serves as a guide, as you
              explore around introductory materials for each SDG, along with
              their subsequent subcategory of corresponding solutions. The idea
              is to collate some high quality and expert publications to orient
              you around key considerations for each SDG topic as you begin or
              deeper your sustainability journey.
            </p>
            <p>
              The resources library listed here is by no means exhaustive, and
              we encourage your contribution to add to the dialogue by submitted{' '}
              <a href="/add-directory">here</a>. Anyone is welcomed to submit.
            </p>
          </div>
          <div className="section">
            <iframe
              className="airtable-embed"
              src="https://airtable.com/embed/shrCbNwhU9hePqTfu?backgroundColor=tealLight&viewControls=on"
              frameBorder="0"
              onmousewheel=""
              width="100%"
              height="533"
              style={{ background: 'transparent', border: '1px solid #ccc' }}
            ></iframe>
          </div>
          <FooterView />
        </div>
      </div>
    );
  };
}

export default DirectoryAirtableView;
