import React from 'react';
import styled from 'styled-components';

import Dropdown from './Dropdown';

const BreadcrumbsContainer = styled.div`
  height: auto;
  display: block;
  position: relative;

  @media all and (min-width: 768px) {
    height: 44px;
  }

  &::before {
    content: '';
    width: 44px;
    height: 44px;
    background-color: ${(props) => (props.single ? '#f26e26' : '#eaeaea')};
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  & > ul {
    padding-left: 22px;
    margin: 0;
    padding: 0;

    & > li {
      display: block;
      position: relative;
      float: unset;
      background-color: #e25203;

      @media all and (min-width: 768px) {
        float: left;

        &::after {
          content: '';
          width: 44px;
          height: 44px;
          position: absolute;
          top: 0;
          right: 0;
          transform: skewX(45deg);
          margin-right: -22px;
          background-color: #e25203;
          transition: all 0.3s linear;
        }
      }

      &:last-child {
        background-color: #f26e26;
        border: none;

        &::after {
          background-color: #f26e26;
        }
      }

      a {
        position: relative;
        display: block;
        padding: 12px 25px 12px 15px;
        font-family: 'Open Sans';
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        text-decoration: none;
        color: #ffffff;
        cursor: pointer;

        &:after {
          content: url(/images/market_map/chevron-down-white.svg);
          position: absolute;
          top: 10px;
          right: 10px;
          margin-left: unset;

          @media all and (min-width: 768px) {
            position: relative;
            top: unset;
            right: unset;
            margin-left: 10px;
          }
        }
      }
    }
  }
`;

function Breadcrumbs({ items = [] }) {
  return (
    <BreadcrumbsContainer single={items.length === 1}>
      {!!items.length && (
        <ul>
          {items.map((item, index) => (
            <li key={index}>
              <a onClick={() => item.setOpen(true)}>{item.text}</a>
              <Dropdown
                open={item.open}
                setOpen={item.setOpen}
                options={item.options}
                selectHandler={item.selectHandler}
              />
            </li>
          ))}
        </ul>
      )}
    </BreadcrumbsContainer>
  );
}

export default Breadcrumbs;
