import React from 'react';
import classnames from 'classnames';

import '../../sass/components/common/StepperView.scss';

class StepperView extends React.Component {
  constructor(props) {
    super();
    this.state = {
    }
  }

  onStepChange = (key) => {
    if (this.props.onStepChange) {
      this.props.onStepChange(key);
    }
  }

  render = () => {
    return (
      <div
        className={classnames('stepperView', {
          'centered': this.props.centered,
        })}
      >
        {this.props.steps.map((step, index) =>
          <div key={step.key} className='itemContainer'>
            <div
              className={classnames({
                'stepItem': true,
                'active': this.props.step === step.key,
              })}
              onClick={() => this.onStepChange(step.key)}
            >
              <span>{index + 1}</span>
              <label>{step.title}</label>
            </div>
          </div>
        )}
      </div>
    )
  }

}

export default StepperView;
