import React from 'react';
import classnames from 'classnames';

import '../../sass/components/input/SearchInput.scss';

export default class SearchInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleChange = (e) => {
    if (this.props.onChange) {
      this.props.onChange(e.target.value);
    }
  }

  render = () => {
    const value = this.props.value || [];
    return (
      <div
        className={classnames('searchInput', {
          'bordered': this.props.bordered,
          'shadowed': this.props.shadowed,
          'transparent': this.props.transparent,
        }, this.props.className)}
      >
        <input
          type='text'
          autoComplete= "new-password"
          placeholder={this.props.placeholder || 'Search...'}
          value={value}
          onChange={this.handleChange}
        />
      </div>
    )
  }

}
