import React, { useEffect, useState } from 'react';

import HeaderMeta from '../common/HeaderMeta';
import { sendRequest } from '../../helpers/RequestDispatcher';
import { triggerEvent } from '../../helpers/global';
import './../../sass/components/market_map/MarketMapSdgListView.scss';

function MarketMapSdgListView() {
  const [goals, setGoals] = useState([]);

  useEffect(() => {
    sendRequest({
      node: true,
      method: 'goals',
      type: 'GET',
      data: {
        order: 'id',
        direction: 'asc',
      },
      success: (data) => {
        setGoals(data.objects);
      },
      error: (err) => {
        triggerEvent('showSnackbar', [{ text: err.message, type: 'error' }]);
      },
    });
  }, []);

  return (
    <>
      <HeaderMeta />
      <div className="marketMapView">
        <div className="header">SDG</div>
        <div>
          <div className="breadcrumbs single">
            <ul>
              <li>
                <a href="#">{!goals.length ? '' : goals.length} SDGs</a>
              </li>
            </ul>
          </div>
          {/* <div className="breadcrumbs">
          <ul>
            <li>
              <a href="#">17 SDGs</a>
            </li>
            <li>
              <a href="#">Responsible production and consumption </a>
            </li>
          </ul>
        </div> */}
        </div>
        <section>
          <div className="sdg-goal-list">
            {goals.map((goal) => (
              <div className="goal">
                <div
                  className="goal-bg-color"
                  style={{ backgroundColor: goal.color }}
                >
                  <div className="goal-id">SDG {goal.id}</div>
                </div>
                <div className="goal-name">{goal.name}</div>
                <div className="goal-image-container">
                  <object
                    type="image/svg+xml"
                    data="/images/market_map/sdg-1.svg"
                  ></object>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
}

export default MarketMapSdgListView;
