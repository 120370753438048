import React from 'react';
import styled from 'styled-components';

import CardList from './CardList';
import Subheader from './Subheader';

export const InformationText = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: #666666;
  margin-top: 12px;
  white-space: pre-wrap;

  @media all and (min-width: 768px) {
    font-size: 20px;
    line-height: 25px;
  }

  @media all and (min-width: 1024px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

const PreparedContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 24px;
`;

const PreparedImage = styled.img`
  margin-right: 35px;

  &:not(:last-child) {
    margin-right: 0;
  }
`;

function Information({ information, expertList }) {
  return (
    <>
      {!!expertList.length && (
        <>
          <Subheader title="As Consulted With" />
          <CardList
            title={`${expertList.length} Expert${
              expertList.length > 1 ? 's' : ''
            }`}
            list={expertList}
          />
        </>
      )}
      <Subheader title="Summary" />
      <InformationText>{information.summary}</InformationText>
      <Subheader title="Glossary" />
      <InformationText>{information.glossary}</InformationText>
      <Subheader title="Prepared By:" />
      <PreparedContainer>
        <PreparedImage src="/images/logo_black.png" />
      </PreparedContainer>
    </>
  );
}

export default Information;
