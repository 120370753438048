import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';

import { sendRequest } from '../helpers/RequestDispatcher.js';

import ProfileCard from './common/ProfileCard';
import SelectInput from './input/SelectInput';
import SelectButton from './common/SelectButton';
import ContentPlaceholder from './common/ContentPlaceholder';

import '../sass/components/DirectoryView.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
  businessProfile: store.data.businessProfile,
  isMobile: store.setup.isMobile,
});

const PROFILE_TYPES = [
  {
    id: 1,
    key: 'organizations',
    label: 'Organizations',
  },
  {
    id: 2,
    key: 'individuals',
    label: 'Individuals',
  },
];
const PROFILES_TYPE = [
  {
    id: 1,
    key: 'Investor',
    label: 'Investor',
  },
  {
    id: 2,
    key: 'Startup',
    label: 'Startup',
  },

  {
    id: 3,
    key: 'Corporate',
    label: 'Corporate',
  },
  {
    id: 4,
    key: 'NGO',
    label: 'NGO',
  },
  {
    id: 5,
    key: 'Academia',
    label: 'Academia',
  },
  {
    id: 6,
    key: 'Government',
    label: 'Government',
  },
  {
    id: 7,
    key: 'Accelerator',
    label: 'Accelerator',
  },
  {
    id: 8,
    key:'Consultant',
    label: 'Consultant',
  },
  {
    id: 9,
    key: 'Others',
    label: 'Others',
  }
];
const INIT_DATA = [
  { url: 'common_data/countries', object: 'countries' },
  {
    url: 'profiles',
    object: 'profiles',
    data: 'getPostsRequestData',
    callback: (ctx, data) => ctx.afterPostRequest(data),
  },
  {
    url: 'common_data/goals',
    object: 'goals',
    callback: (ctx) => ctx.provideDeafaulGoal(),
  },
  {
    url: 'common_data/objectives',
    object: 'objectives',
    callback: (ctx, data) => ctx.setInterest(data),
  },
];

const ITEMS_PER_LOAD = 20;

class DirectoryView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      selectedProfileTypes: ['organizations', 'individuals'],
      selectedProfilesType: ['Investor', 'Startup','Corporate','NGO','Academia','Government','Accelerator','Consultant','Others'],
      countries: [],
      profiles: [],
      goals: [],
      objectives: [],
      interest: {
        id: null,
        key: null,
        title: null,
        subtitle: null,
        objective_type: null,
      },
      loadNumber: 0,
      noMore: false,
      search: {
        country_id: null,
        name: this.props.location.searchName || '',
        order: null,
        goal_id: null,
        profile_type: null,
        profiles_type: null,
        objective_key: this.props.match.params.key,
        business_profile_id: props.businessProfile?.id,
      },
      loaded: false,
      adsTagLoaded: false,
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    let search = this.state.search;
    if (!this.isSearchPage()) {
      delete search['name'];
    }
    if (
      prevProps.user !== this.props.user ||
      prevProps.businessProfile !== this.props.businessProfile
    ) {
      this.getData(
        'profiles',
        'profiles',
        'getPostsRequestData',
        false,
        (ctx, data) => ctx.afterPostRequest(data)
      );
    }
    if (prevProps.location.searchName !== this.props.location.searchName) {
      this.setState(
        {
          search: {
            ...search,
            name: this.props.location.searchName,
          },
        },
        () => {
          this.getData('profiles', 'profiles', this.state.search, true);
        }
      );
    }
  };

  componentDidMount = () => {
    INIT_DATA.forEach((el) =>
      this.getData(el.url, el.object, el.data, el.noLoad, el.callback)
    );
    this.loadGPTag(this.initGPTag());
  };

  loadGPTag = (callback) => {
    const existingScript = document.getElementById('gpt');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
      script.id = 'gpt';
      script.async = true;
      document.body.appendChild(script);
      script.onload = () => {
        if (callback) callback();
      };
    }
    if (existingScript && callback) callback();
  };

  initGPTag = () => {
    const existingScript = document.getElementById('gptInit');
    if (!existingScript) {
      const script = document.createElement('script');
      script.id = 'gptInit';
      script.text = `
        window.googletag = window.googletag || {cmd: []};
        let slot1, slot2, slot3, slot4, slot5, slot6;
        googletag.cmd.push(function() {
          slot1 = googletag.defineSlot('/16222413/SCO-L1', [728, 90], 'div-gpt-ad-1626942035534-0').addService(googletag.pubads());
          slot2 = googletag.defineSlot('/16222413/SCO-L1-MOBILE', [320, 50], 'div-gpt-ad-1626942200946-0').addService(googletag.pubads());
          slot3 = googletag.defineSlot('/16222413/SCO-L2', [728, 90], 'div-gpt-ad-1626951971479-0').addService(googletag.pubads());
          slot4 = googletag.defineSlot('/16222413/SCO-L2-MOBILE', [320, 50], 'div-gpt-ad-1626952078095-0').addService(googletag.pubads());
          slot5 = googletag.defineSlot('/16222413/SCO-R1', [300, 250], 'div-gpt-ad-1626942351037-0').addService(googletag.pubads());
          slot6 = googletag.defineSlot('/16222413/SCO-R2', [300, 250], 'div-gpt-ad-1626942431201-0').addService(googletag.pubads());
          googletag.pubads().enableSingleRequest();
          googletag.pubads().collapseEmptyDivs();
          googletag.enableServices();
          googletag.pubads().refresh([slot1, slot2, slot3, slot4, slot5, slot6]);
        });
      `;
      document.body.appendChild(script);
      this.setState({ adsTagLoaded: true });
    } else {
      const script = document.createElement('script');
      script.id = 'gptRefresh';
      script.text = `
        window.googletag = window.googletag || {cmd: []};
        googletag.cmd.push(function() {
          googletag.pubads().refresh([slot1, slot2]);
        });
      `;
      document.body.appendChild(script);
      this.setState({ adsTagLoaded: true });
    }
  };

  isSearchPage = () => {
    return this.props.match.path.includes('search');
  };

  getPostsRequestData = () => {
    let search = this.state.search;
    if (!this.isSearchPage()) {
      delete search['name'];
    }
    return {
      ...search,
      business_profile_id: this.props.businessProfile?.id,
    };
  };

  getData = (url, object, data = null, noLoad = false, callback = null) => {
    if (data === 'getPostsRequestData') data = this.getPostsRequestData();
    sendRequest({
      method: url,
      type: 'GET',
      data: data,
      noLoad: noLoad,
      success: (data) => {
        this.setState({ [object]: data || [] });
        if (callback) {
          callback(this, data);
        }
      },
      error: (data) => {},
    });
  };

  provideDeafaulGoal = () => {
    this.setState((prevState) => ({
      goals: [{ name: 'All UN SDGs' }].concat(prevState.goals),
    }));
  };

  setInterest = (data) => {
    const interest = data.find((i) => i.key === this.props.match.params.key);
    this.setState({
      interest,
    });
  };

  afterPostRequest = (data) => {
    if (data.length === 0) {
      this.setState({
        noMore: true,
        loaded: true,
      });
    } else {
      this.setState((prevState) => ({
        loadNumber: 1,
        noMore: data.length < ITEMS_PER_LOAD,
        loaded: true,
      }));
    }
  };

  loadMore = () => {
    let search = this.state.search;
    if (!this.isSearchPage()) {
      delete search['name'];
    }
    sendRequest({
      method: 'profiles',
      type: 'GET',
      data: {
        offset: this.state.loadNumber * ITEMS_PER_LOAD,
        ...search,
      },
      noLoad: true,
      success: (data) => {
        this.setState((prevState) => ({
          profiles: prevState.profiles.concat(data),
          loadNumber: prevState.loadNumber + 1,
          noMore: data.length < ITEMS_PER_LOAD,
        }));
      },
      error: (data) => {},
    });
  };

  handleProfileSelectQuery = () => {
    const { selectedProfileTypes, search } = this.state;
    this.setState({
      search: {
        ...search,
        profile_type:
          selectedProfileTypes.length === 1 ? selectedProfileTypes[0] : null,
      },
    });
  };

  handleProfilesSelectQuery = () => {
    const {selectedProfilesType, search } = this.state;
    this.setState({
      search: {
        ...search,
        profiles_type:
          selectedProfilesType.length === 1 ? selectedProfilesType[0] : null,
      },
    });
  };

  handleProfileSelectClick = (item) => {
    const index = this.state.selectedProfileTypes.indexOf(item.key);
    const profileTypes = this.state.selectedProfileTypes;

    if (index >= 0) {
      this.setState(
        {
          selectedProfileTypes: [...profileTypes.filter((i) => i !== item.key)],
        },
        this.handleProfileSelectQuery
      );
    } else {
      this.setState(
        { selectedProfileTypes: [...profileTypes, item.key] },
        this.handleProfileSelectQuery
      );
    }
  };
  handleProfilesSelectClick = (item) => {
    const index = this.state.selectedProfilesType.indexOf(item.key);
    const profilesType = this.state.selectedProfilesType;

    if (index >= 0) {
      this.setState(
        {
          selectedProfilesType: [...profilesType.filter((i) => i !== item.key)],
        },
        this.handleProfilesSelectQuery
      );
    } else {
      this.setState(
        { selectedProfilesType: [...profilesType, item.key] },
        this.handleProfilesSelectQuery
      );
    }
  };

  handleProfileCardClick = (item) => {
    const path = item.type === 'organizations' ? 'business' : 'user';
    this.props.history.push(`/${path}/${item.id}`);
  };

  renderSectionHeader = () => {
    const name = this.state.search.name;
    const interest = this.state.interest;
    return (
      <div className="sectionHeader">
        <div className="headerGroup">
          {this.isSearchPage() ? (
            name ? (
              <>
                <div className="youSearched">You searched for ‘{name}’.</div>
                <div className="resultsFound">
                  {this.state.profiles.length} results found.
                </div>
              </>
            ) : null
          ) : (
            <>
              <div className="header">
                Profiles related to {interest.title}
              </div>
              <div className="subheader">{interest.subtitle}</div>
            </>
          )}
        </div>
      </div>
    );
  };

  renderInput = (properties) => {

    const value = this.state.search[properties.key];
    let search = this.state.search;
    if (!this.isSearchPage()) {
      delete search['name'];
    }
    const commonProps = {
      ...properties,
      value,
      onChange: (val) => {
        this.setState(
          {
            noMore: false,
            loadNumber: 0,
            search: {
              ...search,
              [properties.key]: val,
            },
          },
          () => {
            this.getData(
              'profiles',
              'profiles',
              this.state.search,
              true,
              (ctx, data) => ctx.afterPostRequest(data)
            );
          }
        );
      },
    };
    return (
      <div className="inputBlock" key={properties.key}>
        {properties.title ? <label>{properties.title}</label> : null}
        {properties.type === 'select' ? <SelectInput {...commonProps} /> : null}
      </div>
    );
  };

  renderFilters = () => {
    const { countries, goals } = this.state;
    return (
      <div>
      <div className="sectionFilters">
        <div className="profileTypeSelect">
          See
          {PROFILE_TYPES.map((item) => (
            <SelectButton
              key={item.id}
              item={item}
              selected={this.state.selectedProfileTypes.includes(item.key)}
              onClick={this.handleProfileSelectClick}
            />
          ))}
          </div>
        {this.isSearchPage() ? null : (
          <div className="sortFilters">
            {this.renderInput({
              key: 'goal_id',
              type: 'select',
              title: 'Filter',
              autoComplete: "new-password",
              options: goals,
              clearable: false,
            })}
            {this.renderInput({
              key: 'country_id',
              type: 'select',
              autoComplete: "new-password",
              title: 'Location',
              options: countries,
              placeholder: 'Select location',
              clearable: true,
            })}
            {this.renderInput({
              key: 'order',
              type: 'select',
              autoComplete: "new-password",
              title: 'Sort by',
              options: [
                { id: 'trending', name: 'Most Active' },
                { id: 'name', name: 'By Name' },
                {id: 'desc_order', name: 'Newest to community'},
              ],
              clearable: true,
            })}
          </div>
        )}
        </div>
        <div className="sortprofilesFilters">
          <div className="profilesTypeSelect">
            {PROFILES_TYPE.map((items) => (
              <SelectButton
                key={items.id}
                item={items}
                selected={this.state.selectedProfilesType.includes(items.key)}
                onClick={this.handleProfilesSelectClick}
              />
            ))}
          </div>
        </div>
      </div>
    );
  };

  renderCards = () => {
    const selectedProfileTypes = this.state.selectedProfileTypes;
    const selectedProfilesType = this.state.selectedProfilesType;
    let items = this.state.profiles;

    items = items.filter((item) => selectedProfilesType.includes(item.profile_type));
    items = items.filter((item) => selectedProfileTypes.includes(item.type));
    return (
      <div className="activeUsersContainer">
        {items.map((item) => (
          <ProfileCard
            key={`${item.type}-${item.id}`}
            {...item}
            onClick={() => this.handleProfileCardClick(item)}
          />
        ))}
      </div>
    );
  };

  renderPaywall = () => {
    const interest = this.state.interest;
    return (
      <div
        className={classnames({
          paywall: true,
          emptyListPaywall: !this.state.profiles.length,
        })}
      >
        <div className="paywallGradient" />
        <img src="/images/paywall_logo.png" alt="icon" height="59" width="59" />
        <div className="paywallHeader">
          View all the profiles related to {interest.title}
        </div>
        <div className="paywallText">
          When you upgrade to The SDG Co Pro, you can get access to Pro features
          like this and more.
        </div>
        <button onClick={() => this.props.history.push('/subscribe')}>
          Find out more
        </button>
      </div>
    );
  };

  render = () => {
    const account = this.props.businessProfile
      ? this.props.businessProfile
      : this.props.user;
    return (
      <div className="directoryView">
        <Helmet>
          <title>The SDG Co</title>
          <meta name="description" content="The SDG Co" />
          <meta name="keywords" content="social" />
          <meta property="og:url" content="https://thesdg.co" />
          <meta property="og:site_name" content="The SDG Co" />
          <meta property="og:title" content="The SDG Co" />
          <meta property="og:image" content="https://thesdg.co/thesdg.png" />
          <meta property="og:description" content="The SDG Co" />
          <meta property="og:type" content="website" />
          <meta name="image" content="https://thesdg.co/thesdg.png" /> 
        </Helmet>
        <section className="activeUsers">
          {this.renderSectionHeader()}
          {this.renderFilters()}
          {this.state.loaded ? (
            this.renderCards()
          ) : (
            <div className="userPlaceholderContainer">
              {[...Array(4)].map((e, i) => (
                <div key={i} className="userPlaceholderItem">
                  <ContentPlaceholder type="user" />
                </div>
              ))}
            </div>
          )}
          {this.isSearchPage() ? null : account?.subscribed ? (
            this.state.noMore ? null : (
              <div className="loadMore" onClick={this.loadMore}>
                <p>Load more users</p>
              </div>
            )
          ) : (
            (!account ? null : this.renderPaywall())
          )}
        </section>
        {this.state.adsTagLoaded ? (
          <div
            className={classnames('banner', {
              mobileBanner: this.props.isMobile,
            })}
          >
            {this.props.isMobile ? (
              <div
                id="div-gpt-ad-1626942200946-0"
                style={{ minWidth: '320px', minHeight: '50px' }}
              >
                <script
                  dangerouslySetInnerHTML={{
                    __html: `googletag.cmd.push(function() { googletag.display('div-gpt-ad-1626942200946-0'); });`,
                  }}
                />
              </div>
            ) : (
              <div
                id="div-gpt-ad-1626942035534-0"
                style={{ minWidth: '728px', minHeight: '90px' }}
              >
                <script
                  dangerouslySetInnerHTML={{
                    __html: `googletag.cmd.push(function() { googletag.display('div-gpt-ad-1626942035534-0'); });`,
                  }}
                />
              </div>
            )}
          </div>
        ) : null}
      </div>
    );
  };
}

export default connect(mapStoreToProps)(withRouter(DirectoryView));
