import axios from 'axios';

import { apiUrl, authUrl, nodeV1ApiUrl, triggerEvent } from './global.js';
import Storage from './Storage.js';
import { getAuthToken } from './amplify';

const EXPIRE_TIME = 1 * 24 * 60 * 60 * 1000; // 1 day(s) in ms

const CACHE_PATHS = [/common_data\/.*/];

export const initCache = async () => {
  const cacheData = (await Storage.loadData('cache')) || {};
  const now = Date.now();
  const promises = [];
  Object.keys(cacheData).forEach((key) => {
    const cacheKey = `cache_${key}`;
    if (now - cacheData[key] <= EXPIRE_TIME) {
      promises.push(Storage.loadData(cacheKey));
    } else {
      Storage.setData(cacheKey, null);
      delete cacheData[key];
    }
  });
  await Promise.all(promises);
  Storage.setData('cache', cacheData);
};

const getCachedData = (key) => {
  return Storage.getData(`cache_${key}`);
};

const cacheData = (key, data) => {
  const cacheKey = `cache_${key}`;
  Storage.setData(cacheKey, data);
  const cacheData = Storage.getData('cache') || {};
  cacheData[key] = Date.now();
  Storage.setData('cache', cacheData);
};

export const sendRequest = (properties) => {
  // const cached = getCachedData(properties.method);
  // if (cached && !properties.noCache) {
  //   if (properties.success) {
  //     properties.success(cached);
  //   }
  // } else {
  sendServerRequest(properties);
  // }
};

const sendServerRequest = async (properties) => {
  const token = await getAuthToken();
  const user = localStorage.getItem('thesdg-client:user_attributes');
  const url = properties.node
    ? nodeV1ApiUrl(properties.method)
    : properties.auth
    ? authUrl(properties.method)
    : apiUrl(properties.method);
  const type = properties.type.toUpperCase();
  if (!properties.noLoad) {
    triggerEvent('addLoad');
  }
  axios({
    url,
    method: type,
    data: type === 'GET' ? null : properties.data || properties.formData,
    params: type === 'GET' ? properties.data : null,
    headers: {
      ...(token && { Authorization: token }),
      ...(user && { 'sdg-token': window.btoa(user) }),
    },
    onUploadProgress: (e) => {
      if (properties.progress) {
        properties.progress(e.loaded / e.total);
      }
    },
  })
    .then((response) => {
      if (!properties.noLoad) {
        triggerEvent('removeLoad');
      }
      if (response.status >= 200 && response.status < 400) {
        if (properties.success) {
          properties.success(response.data);
        }
        // if (CACHE_PATHS.some((path) => properties.method.match(path))) {
        //   cacheData(properties.method, response.data);
        // }
      }
    })
    .catch((error) => {
      if (!properties.noLoad) {
        triggerEvent('removeLoad');
      }
      if (error.response) {
        if (error.response.status === 401) {
          triggerEvent('unauthorized');
        }
        if (error.response.status === 503) {
          triggerEvent('showSnackbar', [
            { text: 'Network error, please try again later.', type: 'error' },
          ]);
        }
        if (properties.error) {
          properties.error(error.response.data);
        }
      } else if (error.request) {
        if (properties.error) {
          properties.error('');
        }
      } else {
        if (properties.error) {
          properties.error('');
        }
      }
    });
};
