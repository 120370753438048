import React from 'react';
import classnames from 'classnames';

import FooterView from './FooterView';
import HeaderMeta from './common/HeaderMeta';

import Storage from '../helpers/Storage';

import '../sass/components/AboutView.scss';

class AboutView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      webpSupported: true,
    };
  }

  componentDidMount = () => {
    if (typeof window !== 'undefined') {
      this.setState({ webpSupported: Storage.getSetup('webpSupported') });
    }
  };

  render = () => {
    return (
      <div
        className={classnames('aboutView background', {
          webp: this.state.webpSupported,
        })}
      >
        <HeaderMeta />
        <div className="content">
          <div className="title">About Us</div>
          <div className="section">
            <p>
              The SDG Co is an Eco-Business initiative, supported by partner
              organisations from across the globe.
            </p>
            <p>
              It is the world’s leading sustainability innovation marketplace
              that connects start-ups, entrepreneurs, technology providers and
              investors working on the United Nations’ Sustainable Development
              Goals (SDGs).
            </p>
            <p>
              It draws inspiration from the SDGs, adopted by all United Nations
              Member States in 2015 as a universal call to action to end
              poverty, protect people and the planet. The ‘Co’ in our name
              represents our common humanity and collaboration. The SDG Co is an
              Eco-Business initiative, supported by partner organisations from
              across the globe.
            </p>
            <p>
              While sustainability efforts are growing across the world, there
              is a need to consolidate efforts and strengthen the global
              sustainability innovation ecosystem.
            </p>
            <p>
              Our platform will also provide a shared community space where
              people and businesses can network, exchange ideas, and build
              professional and personal skills and capabilities. We are a
              technology-driven platform and community that will help drive
              these efforts and accelerate our ambition to be climate neutral by
              the second half of the century.
            </p>
          </div>
          <div className="section">
            <p className="bigTextSize">IxSA</p>
            <p>
              The SDG Co is a founding member of the{' '}
              <span>Innovation x Sustainability Alliance (IxSA)</span>. This
              alliance has created the world’s first sustainability innovation
              manifesto which aims to provide a North Star for all
              sustainability start-ups.
            </p>
            <p>
              The manifesto — styled after the Agile Manifesto that guides
              software development — outlines six principles we believe play
              elevated roles in the realm of sustainability.
            </p>
            <p>
              The SDG Co believes this will trigger change in the sustainability
              ecosystem from which various frameworks can be built on, and which
              will guide sustainability start-ups to success.
            </p>
            <p>
              The SDG Co is an ecosystem that supports sustainability
              innovations in reaching global markets and achieving scale. This
              is increasingly recognized as key to achieving national and global
              targets to address complex challenges facing humanity arising from
              issues such as climate change, resource scarcity, pollution,
              rising inequality, and urbanization.
            </p>
          </div>
          <FooterView />
        </div>
      </div>
    );
  };
}

export default AboutView;
