import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

export const DropdownList = styled.div`
  display: ${(props) => (props.open ? 'block' : 'none;')};
  width: 100%;
  max-height: 236px;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  position: absolute;
  margin-left: 0;
  overflow-y: auto;
  position: absolute;
  top: 44px;
  left: 0;
  z-index: 2;

  @media all and (min-width: 768px) {
    width: unset;
    min-width: 428px;
  }

  ${(props) => props.customStyle ?? ''}
`;

export const DropdownItem = styled.div`
  display: block;
  font-family: 'Open Sans';
  font-size: 14px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  cursor: pointer;
  ${(props) => props.customStyle ?? ''}

  &:hover {
    background-color: #f26e26;
    color: #fff;
  }
`;

function useOutsideClick(ref, setOpen) {
  useEffect(() => {
    /**
     * Close if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

function Dropdown({
  open,
  setOpen,
  options,
  selectHandler,
  noMargin = false,
  customListStyle = '',
  customItemStyle = '',
}) {
  const refDropdownList = useRef(null);
  useOutsideClick(refDropdownList, setOpen);

  return (
    <DropdownList
      open={!!open}
      className={`${noMargin ? 'no-margin' : ''}`}
      customStyle={customListStyle}
      ref={refDropdownList}
    >
      {options.map((option, index) => (
        <DropdownItem
          key={option.key ?? index}
          onClick={() => selectHandler(option)}
          customStyle={customItemStyle}
        >
          {option.text ?? option}
        </DropdownItem>
      ))}
    </DropdownList>
  );
}

export default Dropdown;
