import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import update from 'immutability-helper';

import GoalsSelect from './input/GoalsSelect';
import MulticheckInput from './input/MulticheckInput';

import { sendRequest } from '../helpers/RequestDispatcher.js';
import { triggerEvent } from '../helpers/global.js';
import Storage from '../helpers/Storage';

import '../sass/components/EditInterestsView.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
});

class EditInterestsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      objectives: []
    };
  }

  componentDidMount = () => {
    const method = this.props.role === 'individual'
      ? 'me'
      : this.props.organizationId ? `business_profiles_managing/${this.props.organizationId}` : null;

    if (method) {
      sendRequest({
        type: 'GET',
        method: method,
        success: (data) => {
          if (data) {
            this.setState({data});
          }
        },
        error: (data) => {
        }
      });
    }
    sendRequest({
      type: 'GET',
      method: 'common_data/objectives',
      success: (data) => {
        if (data) {
          this.setState({objectives: data});
        }
      },
      error: (data) => {
      }
    });
  }

  onSave = () => {
    if (!this.state.data.id) {
      triggerEvent('showSnackbar', [{text: 'Please complete General Information first.', type: 'error'}]);
      return;
    }
    const { goal_ids, objective_ids } = this.state.data;
    const method = this.props.role === 'individual' ? 'me' : `business_profiles_managing/${this.state.data.id}`;
    if (!goal_ids.length) {
      triggerEvent('showSnackbar', [{text: 'Please select UN Sustainable Development Goals.', type: 'error'}]);
      return;
    }
    if (!objective_ids.length) {
      triggerEvent('showSnackbar', [{text: 'Please select what you are looking to do.', type: 'error'}]);
      return;
    }
    sendRequest({
      method: method,
      type: 'PUT',
      data: {
        goal_ids,
        objective_ids
      },
      success: (data) => {
        if (data) {
          this.setState({data});
          if (!this.props.wizard) {
            if (this.props.role === 'individual') {
              Storage.setData('user', data);
            } else {
              if (this.props.businessProfile?.id === data.id) {
                Storage.setData('businessProfile', data);
              }
              const profileIndex = this.props.user.business_profiles.findIndex(profile => profile.id === data.id);
              Storage.setData('user', {
                ...Storage.getData('user'),
                business_profiles: update(this.props.user.business_profiles, {
                  [profileIndex]: {$set: data}
                }),
              });
            }
          } else {
            if (this.props.role === 'individual') {
              Storage.setData('user', data);
            } else {
              const profileIndex = this.props.user.business_profiles.findIndex(profile => profile.id === data.id);
              Storage.setData('user', {
                ...Storage.getData('user'),
                business_profiles: update(this.props.user.business_profiles, {
                  [profileIndex]: {$set: data}
                }),
              });
            }
          }
        }
        if (this.props.onSave) {
          this.props.onSave();
        }
      },
      error: (data) => {
      }
    });
  }

  onChange = (key, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [key]: value,
      }
    })
  }

  renderGoals = () => {
    return (
      <>
        <div className='interestsTitle'>
          Select UN Sustainable Development Goals<sup>*</sup>
        </div>
        <div className='interestsSubtitle'>
          {this.props.role === 'individual'
            ? 'Select which UN SDGs that you are interested in.'
            : 'Select which UN SDGs that your organisation is interested in.'
          }
        </div>
        <GoalsSelect
          value={this.state.data.goal_ids || []}
          onChange={val => this.onChange('goal_ids', val)}
        />
      </>
    )
  }

  renderObjectives = () => {
    const objectives = this.props.role === 'individual'
      ? this.state.objectives.filter(objective => ['any', 'individual'].indexOf(objective.objective_type) > -1)
      : this.state.objectives.filter(objective => ['any', 'organisation'].indexOf(objective.objective_type) > -1)
    return (
      <>
        <div className='interestsTitle'>
          {this.props.role === 'individual'
            ? <>Select what you are looking to do<sup>*</sup></>
            : 'Select what your Organisation is looking to do'
          }
        </div>
        <div className='interestsSubtitle'>
          What are you looking for in our Ecosystem?
        </div>
        <MulticheckInput
          items={objectives}
          value={this.state.data.objective_ids || []}
          onChange={val => this.onChange('objective_ids', val)}
        />
      </>
    )
  }

  render = () => {
    return (
      <div
        className={classnames('editInterestsView', {
          'wizard': this.props.wizard,
        })}
      >
        <div className='interestsContent'>
          {this.renderGoals()}
          {this.renderObjectives()}
        </div>
        <div className='buttonBlock'>
          <button onClick={this.onSave}>
            {this.props.wizard ? 'Continue' : 'Save'}
          </button>
        </div>
      </div>
    )
  }

}

export default connect(mapStoreToProps)(withRouter(EditInterestsView));
