import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CSSTransition from 'react-transition-group/CSSTransition';
import classnames from 'classnames';
import Auth from '@aws-amplify/auth';

import AuthPopup from './auth/AuthPopup';
import SearchInput from './input/SearchInput';
import SwitchProfileView from './SwitchProfileView';
import Avatar from './common/Avatar';

import Storage from '../helpers/Storage';
import { triggerEvent, eventOn } from '../helpers/global';
import { signOut } from '../helpers/amplify';

import '../sass/components/HeaderView.scss';
import PostFormPopup from './common/PostFormPopup';

const mapStoreToProps = (store) => ({
  user: store.data.user,
  businessProfile: store.data.businessProfile,
});

const MAIN_NAV = [
  { name: 'about', route: '/about' },
  { name: 'browse', route: '/browse' },
  { name: 'news', route: 'https://www.eco-business.com/', external: true },
  {
    name: 'events',
    subnav: [
      { name: 'View Events', route: '/events', className: 'subnav-dropdown' },
      {
        name: 'Submit Event',
        route:
          'https://docs.google.com/forms/d/1JsjIr4tKRZgE4EVMcEJiXxyy3MyBbqbmI2UOqdO_Ou8/prefill',
        external: true,
        className: 'subnav-dropdown',
      },
    ],
  },
  {
    name: 'resources',
    subnav: [
      {
        name: 'View directory',
        route: '/directory',
        className: 'subnav-dropdown',
      },
      {
        name: 'Add to directory',
        route: '/add-directory',
        className: 'subnav-dropdown',
      },
    ],
  },
];

const WAIT_INTERVAL = 500;

class HeaderView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      isMobile: false,
      mobileMenuHidden: true,
      showMobileMenu: false,
      showPopupForm: false,
      data: this.defaultData(),
    };
    this.timer = null;
  }

  componentDidMount = () => {
    if (typeof window !== 'undefined') {
      eventOn(
        'resize',
        (e) => {
          this.getSetting();
        },
        window
      );
      this.getSetting();
    }
  };

  getSetting = () => {
    this.setState({ isMobile: Storage.getSetup('isMobile') });
  };

  ownProfile = () => {
    const { businessProfile, user } = this.props;
    return businessProfile ? businessProfile : user;
  };

  showLogin = () => {
    triggerEvent('showContentPopup', [
      {
        content: (
          <AuthPopup
            key={Date.now()}
            onLogin={() => triggerEvent('hideContentPopup')}
          />
        ),
      },
    ]);
  };

  showLogout = () => {
    triggerEvent('showConfirmation', [
      {
        title: 'Are you sure you want to log out?',
        overlayBlock: true,
        callback: this.logoutAction,
      },
    ]);
  };

  showSwitchAccount = () => {
    triggerEvent('showContentPopup', [
      {
        title: 'Switch Accounts',
        switchContent: true,
        content: <SwitchProfileView />,
      },
    ]);
  };

  showPostForm = () => {
    this.setState({ showPopupForm: true });
  };

  logoutAction = (action) => {
    if (action) {
      triggerEvent('addLoad');
      const amplifySignOut = async () => {
        const successSignOut = await signOut();
        if (successSignOut) {
          Storage.setData('user', null);
          Storage.setData('businessProfile', null);
          localStorage.removeItem('thesdg-client:user_attributes');
          triggerEvent('showSnackbar', [
            { text: 'Success logout', type: 'success' },
          ]);
        } else {
          triggerEvent('showSnackbar', [
            {
              text: 'Failure logout',
              type: 'error',
            },
          ]);
        }
        triggerEvent('removeLoad');
      };
      amplifySignOut();
    }
  };

  handleCloseMobileMenu = () => {
    this.setState({ showMobileMenu: false });
  };

  handleNavToggleClick = () => {
    this.setState({
      mobileMenuHidden: false,
      showMobileMenu: true,
    });
  };

  handleAction = (action) => {
    if (action === 'logout') {
      this.showLogout();
    } else if (action === 'switchAccount') {
      this.showSwitchAccount();
    } else if (action === 'addPost') {
      this.showPostForm();
    }
  };

  handleSearch = (value) => {
    clearTimeout(this.timer);
    this.setState({ search: value });
    if (value) {
      this.timer = setTimeout(this.triggerSearch, WAIT_INTERVAL);
    }
  };

  triggerSearch = () => {
    // TODO: think of a better way to pass searchValue
    this.props.history.push({
      pathname: '/search',
      searchName: this.state.search,
    });
  };

  renderNavigation = () => {
    const user = this.props.user;
    return (
      <div className="headerNavigation">
        {MAIN_NAV.map((item, index) => this.renderNavItem(item, index))}
        <div className="navSeparator" />
        {user ? (
          <div className="userNavItem">{this.renderUserNav()}</div>
        ) : (
          <>
            <div className="navItem">
              <a className="transparent" onClick={this.showLogin}>
                Log in
              </a>
            </div>
            <div
              className="navItem button"
              onClick={() => this.props.history.push('/register')}
            >
              Join the Ecosystem
            </div>
          </>
        )}
      </div>
    );
  };

  renderNavItemImage = (image, username) => {
    const imageUrl = image === 'default' ? null : image;
    return (
      <div className="navItemImage">
        <Avatar imageUrl={imageUrl} username={username} tiny />
      </div>
    );
  };

  renderNavItem = (item, index = 0, subIndex = 0) => {
    const profile = this.ownProfile();
    const key = `${index}-${subIndex}`;
    const nested = (item.subnav || []).length > 0;
    const subNavWithDropdown = !!nested && !!item.subnav[0].className;
    const active = key === this.state.activeMobileItem;
    const className = !!item.className;
    return (
      <div
        key={key}
        className={classnames(
          'navItem',
          { nested: nested },
          { 'subnav-dropdown': className },
          { subNavWithDropdown: subNavWithDropdown }
        )}
      >
        <a
          className={classnames({ active: active })}
          onClick={() => {
            if (this.state.showMobileMenu) {
              this.handleCloseMobileMenu();
            }
            if (item.action) {
              this.handleAction(item.action);
            } else if (item.route && item.external) {
              window.open(item.route, '_blank');
            } else if (item.route) {
              this.props.history.push(item.route);
            }
          }}
        >
          {item.image_url
            ? this.renderNavItemImage(item.image_url, item.name)
            : null}
          <span>{item.name}</span>
          {nested ? <div className="dropdown-icon" /> : null}

          {!!profile && !!profile.subscription_enabled && item.subscribed ? (
            <span className="subscribed" />
          ) : null}
        </a>
        {nested ? (
          <div
            className={classnames(
              'subNav',
              {
                active: active,
              },
              {
                subNavWithDropdown: subNavWithDropdown,
              }
            )}
          >
            {item.subnav.map((sub, i) => this.renderNavItem(sub, index, i))}
          </div>
        ) : null}
      </div>
    );
  };
  defaultData = (goals) => {
    const { key } = this.props.match.params;
    const goalId = goals ? goals.find((i) => i.key === key)?.id : null;
    return {
      authorTitle: '',
      postId: null,
      file: null,
      goalIds: [goalId],
      content: '',
      title: '',
      link: '',
    };
  };

  postFormPopupCallback = (data) => {
    this.setState({
      showPopupForm: false,
      data: this.defaultData(this.state.goals),
    });
  };

  renderUserNav = () => {
    const profile = this.ownProfile();
    if (!profile) {
      return null;
    }
    const editAccount = profile.user_id
      ? { name: 'Edit organization', route: `/business/${profile.id}/edit` }
      : {
          name: 'Edit profile',
          route: `/user/${profile.slug || profile.id}/edit`,
        };
    const account = profile.user_id
      ? { name: 'Organization profile', route: `/business/${profile.id}` }
      : { name: 'Profile', route: '/profile' };

    const subnav = [
      account,
      editAccount,
      { name: 'Organizations', route: '/organizations' },
      { name: 'Chats', route: '/chats' },
      { name: 'Switch Account', action: 'switchAccount' },
      { name: 'Post Content', action: 'addPost' },
      { name: 'Log out', action: 'logout' },
    ];

    // remove 'Organizations' if profile is business
    profile.user_id && subnav.splice(2, 1);
    // remove 'Edit organization' if user not owner
    profile.user_id &&
      profile.user_id !== this.props.user.id &&
      subnav.splice(1, 1);

    return this.renderNavItem({
      name: profile.name || 'You',
      image_url: profile.image || 'default',
      subscribed: profile.subscribed,
      subnav: subnav,
    });
  };

  renderMobileNav = () => {
    return (
      <div
        className={classnames({
          mobileMenuWrap: true,
          hidden: this.state.mobileMenuHidden,
        })}
      >
        <CSSTransition
          in={this.state.showMobileMenu}
          timeout={350}
          classNames="fade"
          onEnter={() => this.setState({ mobileMenuHidden: false })}
          onExited={() => this.setState({ mobileMenuHidden: true })}
        >
          <div className="overlay" onClick={this.handleCloseMobileMenu} />
        </CSSTransition>
        <CSSTransition
          in={this.state.showMobileMenu}
          timeout={350}
          classNames="slide"
          onEnter={() => this.setState({ mobileMenuHidden: false })}
          onExited={() => this.setState({ mobileMenuHidden: true })}
        >
          <div className="mobileMenu">
            <div className="closeButton" onClick={this.handleCloseMobileMenu} />
            <div className="mobileLogoWrap">
              <div
                className="headerLogo dark"
                onClick={() => this.props.history.push('/')}
              />
            </div>
            <SearchInput
              bordered
              placeholder="Search Profiles..."
              value={this.state.search}
              onChange={(value) => this.handleSearch(value)}
            />
            {this.renderNavigation()}
          </div>
        </CSSTransition>
      </div>
    );
  };

  renderHeaderView = () => {
    return (
      <div className="header">
        <div
          className="headerLogo"
          onClick={() => this.props.history.push('/')}
        />
        {this.state.isMobile ? (
          <>
            {this.renderMobileNav()}
            <div className="navToggle" onClick={this.handleNavToggleClick} />
            <div className="underlay" />
          </>
        ) : (
          <>
            <SearchInput
              shadowed
              transparent={this.props.transparentSearch}
              placeholder="Search Profiles..."
              value={this.state.search}
              onChange={(value) => this.handleSearch(value)}
            />
            {this.renderNavigation()}
          </>
        )}
      </div>
    );
  };

  render = () => {
    return (
      <div
        className={classnames({
          headerView: true,
          homeHeader: this.props.transparentSearch,
        })}
      >
        <div className="headerBlock">
          <div className="headerContent">{this.renderHeaderView()}</div>
        </div>
        {!!this.ownProfile() && (
          <PostFormPopup
            authorTitle={this.state.data.authorTitle}
            goalIds={this.state.data.goalIds}
            show={this.state.showPopupForm}
            file={this.state.data.file}
            postId={this.state.data.postId}
            callback={this.postFormPopupCallback}
            content={this.state.data.content}
            title={this.state.data.title}
            link={this.state.data.link}
          />
        )}
      </div>
    );
  };
}

export default connect(mapStoreToProps)(withRouter(HeaderView));
