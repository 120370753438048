import React, { useEffect, useState } from 'react';
import { withRouter, Link, useParams } from 'react-router-dom';
import { Modal, Button } from 'semantic-ui-react';
import styled from 'styled-components';

import { sendRequest } from '../../helpers/RequestDispatcher';
import { triggerEvent } from '../../helpers/global';
import { loadOptions } from '../../helpers/market_map';
import HeaderMeta from '../common/HeaderMeta';
import Breadcrumbs from './shared/Breadcrumbs';
import MarketMapForm from './shared/MarketMapForm';
import { RemoteSubmit } from './shared/CompanyForm';
import Subheader from './shared/Subheader';
import './../../sass/components/market_map/MarketMapListView.scss';

const MarketMapList = styled.div`
  display: flex;
  flex-wrap: wrap;

  a {
    text-decoration: none;
  }
`;
const Card = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 172px;
  height: 174px;
  /* alternate to border with border-collapse */
  box-shadow: 1px 0 0 0 #ccc, 0 1px 0 0 #ccc, 1px 1px 0 0 #ccc,
    1px 0 0 0 #ccc inset, 0 1px 0 0 #ccc inset;
  cursor: pointer;
  padding-top: 15px;
  padding-bottom: 11px;
  padding-left: 10px;
  padding-right: 10px;

  @media all and (min-width: 768px) {
    width: 222px;
    height: 224px;
    padding-top: 30px;
    padding-bottom: 22px;
  }
`;
const CardTitle = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
  text-align: center;
  color: #666666;
  flex: 1;

  @media all and (min-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;
const CardCount = styled.div`
  font-family: 'Tiempo';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  color: #f26e26;

  @media all and (min-width: 768px) {
    font-size: 30px;
    line-height: 42px;
  }
`;
const CardSubtitle = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
  text-align: center;
  color: #999999;

  @media all and (min-width: 768px) {
    font-size: 14px;
    line-height: 30px;
  }
`;
const CardAdd = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 172px;
  height: 174px;
  background: #f26e26;
  box-shadow: 1px 0 0 0 #f26e26, 0 1px 0 0 #f26e26, 1px 1px 0 0 #f26e26,
    1px 0 0 0 #f26e26 inset, 0 1px 0 0 #f26e26 inset;
  cursor: pointer;

  @media all and (min-width: 768px) {
    width: 222px;
    height: 224px;
  }

  .plus-icon {
    width: 45px;
    height: 45px;
    background-image: url(/images/market_map/plus-white.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    margin-bottom: 24px;
  }

  .add-text {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }
`;

function MarketMapSubcategoryListView(props) {
  const [optionsGoal, setOptionsGoal] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [openDropdownGoal, setOpenDropdownGoal] = useState(false);
  const [marketMaps, setMarketMaps] = useState([]);
  const params = useParams();
  const [openAddMarketMap, setOpenAddMarketMap] = useState(false);
  const [initialValuesMarketMap, setInitialValuesMarketMap] = useState({
    name: '',
    goal_id: null,
  });
  const [isSubmitMarketMap, setIsSubmitMarketMap] = useState(false);
  const [savingAddMarketMap, setSavingAddMarketMap] = useState(false);
  const [loadingMarketMaps, setLoadingMarketMaps] = useState(false);

  const loadMarketMaps = () => {
    setLoadingMarketMaps(true);
    sendRequest({
      node: true,
      method: 'market-maps',
      type: 'GET',
      data: {
        goal_id: selectedGoal.value,
        order: 'id',
        direction: 'asc',
      },
      success: (data) => {
        setMarketMaps(data.objects);
        setLoadingMarketMaps(false);
      },
      error: (err) => {
        triggerEvent('showSnackbar', [{ text: err.message, type: 'error' }]);
        setLoadingMarketMaps(false);
      },
    });
  };

  const handleOnSelectGoal = (goal) => {
    props.history.push(`/market-maps/${goal.key}`);
    setSelectedGoal(goal);
    setOpenDropdownGoal(false);
  };

  const handleOnAddMarketMap = () => {
    setInitialValuesMarketMap({
      ...initialValuesMarketMap,
      goal_id: selectedGoal.value,
    });
    setOpenAddMarketMap(true);
  };

  const handleCloseModalMarketMap = () => {
    setOpenAddMarketMap(false);
    setIsSubmitMarketMap(false);
  };

  const handleRemoteSubmitFormMarketMap = () => {
    setIsSubmitMarketMap(true);
  };

  const handleSubmitAddMarketMap = (values) => {
    setSavingAddMarketMap(true);
    sendRequest({
      node: true,
      method: 'market-map-requests',
      type: 'POST',
      data: values,
      success: (_) => {
        triggerEvent('showSnackbar', [
          { text: 'Add Market Map request successful', type: 'success' },
        ]);
        setSavingAddMarketMap(false);
        setOpenAddMarketMap(false);
      },
      error: (err) => {
        setSavingAddMarketMap(false);
        triggerEvent('showSnackbar', [{ text: err.message, type: 'error' }]);
      },
    });
  };

  useEffect(() => {
    loadOptions({
      route: 'goals',
      setter: setOptionsGoal,
    });
  }, []);

  useEffect(() => {
    if (!!optionsGoal.length) {
      if (params.slug) {
        const findOption = optionsGoal.find(
          (value) => value.key === params.slug
        );
        if (findOption) {
          setSelectedGoal(findOption);
        }
      } else {
        handleOnSelectGoal(optionsGoal[0]);
      }
    }
  }, [optionsGoal, params.slug]);

  useEffect(() => {
    if (selectedGoal) {
      loadMarketMaps();
    }
  }, [selectedGoal]);

  return (
    <>
      <HeaderMeta />
      <div className="marketMapView">
        <div className="header market-map-header">MARKET MAPS</div>
        <div>
          <Breadcrumbs
            items={[
              {
                setOpen: setOpenDropdownGoal,
                text: selectedGoal ? selectedGoal.text : 'Select SDG',
                open: openDropdownGoal,
                options: optionsGoal,
                selectHandler: handleOnSelectGoal,
              },
            ]}
          />
        </div>
        <section>
          <MarketMapList>
            {!loadingMarketMaps && !selectedGoal && (
              <Subheader title="Select SDG" />
            )}
            {!loadingMarketMaps && selectedGoal && (
              <>
                {!!marketMaps.length &&
                  marketMaps.map((value) => (
                    <Link
                      key={value.id}
                      to={`/market-maps/${selectedGoal.key}/${value.slug}`}
                    >
                      <Card>
                        <CardTitle>{value.name}</CardTitle>
                        <CardCount>{value.count_companies}</CardCount>
                        <CardSubtitle>Companies Highlighted</CardSubtitle>
                      </Card>
                    </Link>
                  ))}
                <CardAdd onClick={() => handleOnAddMarketMap()}>
                  <div className="plus-icon"></div>
                  <div className="add-text">Add Market Map</div>
                </CardAdd>
              </>
            )}
          </MarketMapList>
        </section>
      </div>
      <Modal
        onClose={() => handleCloseModalMarketMap()}
        onOpen={() => setOpenAddMarketMap(true)}
        open={openAddMarketMap}
        size="small"
        closeOnDimmerClick={false}
        closeIcon
      >
        <Modal.Header size="medium">Add Market Map</Modal.Header>
        <Modal.Content scrolling>
          <MarketMapForm
            formValues={initialValuesMarketMap}
            onSubmitHandler={handleSubmitAddMarketMap}
            renderRemoteSubmit={() => (
              <RemoteSubmit
                submit={isSubmitMarketMap}
                setSubmit={setIsSubmitMarketMap}
              />
            )}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => handleCloseModalMarketMap()}
            disabled={savingAddMarketMap}
          >
            Close
          </Button>
          <Button
            positive
            onClick={() => handleRemoteSubmitFormMarketMap()}
            disabled={savingAddMarketMap}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default withRouter(MarketMapSubcategoryListView);
