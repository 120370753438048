import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import update from 'immutability-helper';

import TextInput from './input/TextInput';
import SelectInput from './input/SelectInput';
import ImageInput from './input/ImageInput';

import Storage from '../helpers/Storage';
import { triggerEvent } from '../helpers/global.js';
import { sendRequest } from '../helpers/RequestDispatcher.js';

import '../sass/components/EditProfileView.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
  businessProfile: store.data.businessProfile,
});

const PROFILE_TYPES = [
  {
    id: 'Investor',
    key: 'Investor',
    name: 'Investor',
  },
  {
    id: 'Startup',
    key: 'Startup',
    name: 'Startup',
  },

  {
    id: 'Corporate',
    key: 'Corporate',
    name: 'Corporate',
  },
  {
    id: 'NGO',
    key: 'NGO',
    name: 'NGO',
  },
  {
    id: 'Academia',
    key: 'Academia',
    name: 'Academia',
  },
  {
    id: 'Government',
    key: 'Government',
    name: 'Government',
  },
  {
    id: 'Accelerator',
    key: 'Accelerator',
    name: 'Accelerator',
  },
  {
    id: 'Consultant',
    key: 'Consultant',
    name: 'Consultant',
  },
  {
    id: 'Others',
    key: 'Others',
    name: 'Others',
  }
];

const PAIRED_FIELDS = {
  first_name: {
    type: 'text',
    title: 'First Name',
    required: true,
  },
  last_name: {
    type: 'text',
    title: 'Last Name',
    required: true,
  },
};

const ORGANISATION_FIELDS = {
  name: {
    type: 'text',
    title: 'Organisation Name',
    required: true,
  },
  country_id: {
    type: 'select',
    title: 'Based in Country',
    autoComplete: "new-password",
    request: 'common_data/countries',
    placeholder: 'Select a Country',
    required: true,
  },
  website: {
    type: 'text',
    title: 'Organisation Website',
    placeholder: 'https://',
  },
  twitter_url: {
    type: 'text',
    title: 'Twitter Handle',
    placeholder: '@handlename',
  },
  linkedin_url: {
    type: 'text',
    title: 'Linkedin URL',
    placeholder: 'www.linkedin.com/company/username',
    required: true,
  },
  facebook_url: {
    type: 'text',
    title: 'Facebook Handle',
    placeholder: '@handlename',
  },
  instagram_url: {
    type: 'text',
    title: 'Instagram Handle',
    placeholder: '@handlename',
  },
  profile_type: {
    type: 'select',
    title: 'Profile Type',
    placeholder: 'Select a Profile Type',
    options: PROFILE_TYPES,
    clearable: false,
    required: true,
  },
  cover_photo: {
    type: 'image',
    title: 'Cover Photo',
    placeholder: 'Upload Cover Photo',
    recommendations: 'Recommended dimensions: 1033px (w) by 438px (h)',
  },
  description: {
    type: 'textarea',
    title: 'Organisation Description',
    height: '260px',
    maxCharacters: 2000,
    required: true,
  },
};

const INDIVIDUAL_FIELDS = {
  description: {
    type: 'text',
    title: 'Headline',
    placeholder: 'Keep your headline short and concise',
    required: true,
  },
  twitter_url: {
    type: 'text',
    title: 'Twitter Handle',
    placeholder: '@handlename',
  },
  linkedin_url: {
    type: 'text',
    title: 'Linkedin URL',
    placeholder: 'www.linkedin.com/company/username',
    required: true,
  },
  facebook_url: {
    type: 'text',
    title: 'Facebook Handle',
    placeholder: '@handlename',
  },
  instagram_url: {
    type: 'text',
    title: 'Instagram Handle',
    placeholder: '@handlename',
  },
  profile_type: {
    type: 'select',
    title: 'Profile Type',
    placeholder: 'Select a Profile Type',
    options: PROFILE_TYPES,
    clearable: false,
    required: true,
  },
  country_id: {
    type: 'select',
    title: 'Based in Country',
    request: 'common_data/countries',
    placeholder: 'Select a Country',
    required: true,
  },
  bio: {
    type: 'textarea',
    title: 'Your bio',
    height: '260px',
    maxCharacters: 2000,
    required: true,
  },
};

class EditProfileView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {}
    };
  }

  componentDidMount = () => {
    this.getData();
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.location.pathname !== this.props.location.pathname
      || prevProps.organizationId !== this.props.organizationId) {
      this.getData();
    }
  }

  getData = () => {
    if (this.props.role === 'individual') {
      sendRequest({
        type: 'GET',
        method: 'me',
        success: (data) => {
          if (data) {
            this.setState({data});
          }
        },
        error: (data) => {
        }
      });
    } else {
      if (this.props.organizationId) {
        sendRequest({
          type: 'GET',
          method: `business_profiles_managing/${this.props.organizationId}`,
          success: (data) => {
            if (data) {
              this.setState({data});
            }
          },
          error: (data) => {
          }
        });
      }
    }
  }

  onSave = () => {
    const felds = this.props.role === 'individual' ? INDIVIDUAL_FIELDS : ORGANISATION_FIELDS;
    const method = this.props.role === 'individual'
      ? 'me'
      : this.state.data.id ? `business_profiles_managing/${this.state.data.id}` : 'business_profiles_managing';

    let formData = new FormData();
    let errors = {};
    if (this.props.role === 'individual') {
      const { first_name, last_name, bio, description, country_id,profile_type, linkedin_url } = this.state.data;
      if (!first_name) {
        errors.first_name = 'Please enter First Name';
      }
      if (!last_name) {
        errors.last_name = 'Please enter Last Name';
      }
      if (!description) {
        errors.description = 'Please enter Headline';
      }
      if (!country_id) {
        errors.country_id = 'Please select Country';
      }
      if (!profile_type) {
        errors.profile_type = 'Please select Profile Type';
      }
      if (!linkedin_url) {
        errors.linkedin_url = 'Please select Linked URL';
      }
      if (!bio) {
        errors.bio = 'Please enter Description';
      }
      this.setState({errors});
      if (Object.keys(errors).length > 0) {
        return;
      }
      Object.keys(PAIRED_FIELDS).forEach(key => {
        const value = this.state.data[key];
        if (value) {
          formData.append(key, value);
        }
      });
    } else {
      const { name, description, country_id, profile_type, linkedin_url } = this.state.data;
      if (!name) {
        errors.name = 'Please enter Name';
      }
      if (!description) {
        errors.description = 'Please enter Description';
      }
      if (!country_id) {
        errors.country_id = 'Please select Country';
      }
      if (!profile_type) {
        errors.profile_type = 'Please select Profile Type';
      }
      if (!linkedin_url) {
        errors.linkedin_url = 'Please select Linked URL';
      }
      this.setState({errors});
      if (Object.keys(errors).length > 0) {
        return;
      }
    }
    const image = this.state.data && this.state.data.image;
    if (image && typeof image !== 'string') {
      formData.append('image', image);
    }
    Object.keys(felds).forEach(key => {
      const value = this.state.data[key];
      if (!value) {
        return;
      }
      if (['image', 'file'].indexOf(felds[key].type) > -1) {
        if (typeof value !== 'string') {
          formData.append(key, value);
        }
      } else {
        formData.append(key, value);
      }
    });
    sendRequest({
      method: method,
      type: this.state.data.id || !this.props.wizard ? 'PUT' : 'POST',
      formData,
      success: (data) => {
        if (data) {
          this.setState({data});
          if (!this.props.wizard) {
            if (this.props.role === 'individual') {
              Storage.setData('user', data);
            } else {
              if (this.props.businessProfile?.id === data.id) {
                Storage.setData('businessProfile', data);
              }
              const profileIndex = this.props.user.business_profiles.findIndex(profile => profile.id === data.id);
              Storage.setData('user', {
                ...Storage.getData('user'),
                business_profiles: update(this.props.user.business_profiles, {
                  [profileIndex]: {$set: data}
                }),
              });
            }
          } else {
            if (this.props.role === 'individual') {
              Storage.setData('user', data);
            } else {
              Storage.setData('user', {
                ...Storage.getData('user'),
                business_profiles: [...this.props.user.business_profiles, data],
              });
            }
          }
        }
        if (this.props.handleOrganization && this.props.role !== 'individual') {
          this.props.handleOrganization(data.id);
        }
        if (this.props.onSave) {
          this.props.onSave();
        }
      },
      error: (data) => {
        if (data.errors && data.errors.name) {
          triggerEvent('showSnackbar', [{text: data.errors.name, type: 'error'}]);
        } else if (data.errors && data.errors.description) {
          triggerEvent('showSnackbar', [{text: data.errors.description, type: 'error'}]);
        }else if (data.errors) {
          triggerEvent('showSnackbar',[{text: data.errors, type: 'error'}]);
        }
      }
    });
  }

  renderInput = (properties) => {
    const value = this.state.data[properties.key];
    const commonProps = {
      ...properties,
      value,
      error: this.state.errors[properties.key],
      onChange: val => {
        if (properties.maxCharacters) {
          val = val.substr(0, properties.maxCharacters);
        }
        this.setState({
          data: {
            ...this.state.data,
            [properties.key]: val,
          }
        })
      },
    }
    return (
      <div className='inputBlock' key={properties.key}>
        {properties.title ?
          <label>{properties.title}{properties.required ? <sup>*</sup> : null}</label>
          : null}
        {properties.maxCharacters ?
          <div className='characterCounter'>{(value || '').length} / {properties.maxCharacters}</div>
        : null}
        {['text', 'textarea'].includes(properties.type) ? <TextInput {...commonProps}/> : null }
        {properties.type === 'select' ? <SelectInput {...commonProps}/> : null }
        {properties.type === 'image' ? <ImageInput {...commonProps}/> : null }
      </div>
    )
  }

  renderIndividualForm = () => {
    return (
      <>
        <div className='pairedInputBlock'>
          {Object.keys(PAIRED_FIELDS).map(key => this.renderInput({key, ...PAIRED_FIELDS[key]}))}
        </div>
        {Object.keys(INDIVIDUAL_FIELDS).map(key => this.renderInput({key, ...INDIVIDUAL_FIELDS[key]}))}
      </>
    );
  }

  renderIndividualImage = () => {
    return this.renderInput({
      key: 'image',
      type: 'image',
      title: 'Profile Photo',
      placeholder: 'Upload Photo',
      recommendations: 'Recommended dimensions: 500px (w) by 500px (h)',
      round: true,
    });
  }

  renderOrganisationForm = () => {
    return Object.keys(ORGANISATION_FIELDS).map(key => this.renderInput({key, ...ORGANISATION_FIELDS[key]}));
  }

  renderOrganisationImage = () => {
    return this.renderInput({
      key: 'image',
      type: 'image',
      title: 'Profile Logo',
      placeholder: 'Upload Logo',
      recommendations: 'Recommended dimensions: 500px (w) by 500px (h)',
    });
  }

  render = () => {
    return (
      <div
        className={classnames('editProfileView', {
          'wizard': this.props.wizard,
        })}
      >
        <div className='profileContent'>
          {this.props.role === 'individual' ? <>
            <div className='profileForm'>{this.renderIndividualForm()}</div>
            <div className='profileImage'>{this.renderIndividualImage()}</div>
          </> : <>
            <div className='profileForm'>{this.renderOrganisationForm()}</div>
            <div className='profileImage'>{this.renderOrganisationImage()}</div>
          </>}
        </div>
        <div className='buttonBlock'>
          <button onClick={this.onSave}>
            {this.props.wizard ? 'Continue' : 'Save'}
          </button>
        </div>
      </div>
    )
  }

}

export default connect(mapStoreToProps)(withRouter(EditProfileView));
