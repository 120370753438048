import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Auth from '@aws-amplify/auth';

import TextInput from '../components/input/TextInput';
import { triggerEvent } from '../helpers/global.js';
import { sendRequest } from '../helpers/RequestDispatcher.js';
import Storage from '../helpers/Storage';
import { signIn } from '../helpers/amplify';

import '../sass/components/RegisterView.scss';

class RegisterView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: null,
      data: {
        email: '',
        password: '',
        confirmPassword: '',
      },
      errors: {},
    };
  }

  saveUser = (type) => {
    const { email, password, confirmPassword } = this.state.data;
    let errors = {};
    if (!email) {
      errors.email = 'Please enter Email';
    }
    if (!password) {
      errors.password = 'Please enter Password';
    }
    if (!confirmPassword) {
      errors.confirmPassword = 'Please confirm Password';
    }
    if (password !== confirmPassword) {
      errors.confirmPassword = "Password doesn't match";
    }
    if (password.length < 8) {
      errors.password = 'Password must be at least 8 characters';
    }
    this.setState({ errors });
    if (Object.keys(errors).length > 0) {
      return;
    }
    triggerEvent('addLoad');
    sendRequest({
      method: 'auth/register',
      type: 'POST',
      data: {
        email,
        password,
        password_confirmation: confirmPassword,
        name: 'test',
      },
      noLoad: true,
      success: (data) => {
        if (data.access_token) {
          const amplifySignIn = async () => {
            const response = await signIn(email, password);

            if (response) {
              this.loginAction(response);
              this.props.history.push(`wizard/${type}`);
            }

            triggerEvent('removeLoad');
          };
          amplifySignIn();
        }
      },
      error: (data) => {
        triggerEvent('removeLoad');
        if (data.errors && data.errors.email) {
          triggerEvent('showSnackbar', [
            { text: data.errors.email, type: 'error' },
          ]);
        } else if (data.errors) {
          triggerEvent('showSnackbar', [{ text: data.errors, type: 'error' }]);
        }
      },
    });
  };

  loginAction = (user) => {
    localStorage.setItem(
      'thesdg-client:user_attributes',
      JSON.stringify(user.attributes)
    );
    sendRequest({
      method: 'me',
      type: 'GET',
      success: (data) => {
        Storage.setData('user', data);
      },
      error: (data) => {},
    });
  };

  renderTypeSelect = () => {
    return (
      <div className="registerContainer">
        <div className="registerContent">
          <div className="registerTitle">
            Join our global community of peers on the front-line of sustainability innovation
          </div>
          <div className="registerSubtitle">
            Are you joining as an Individual or as an Organisation?
          </div>
          <div className="registerSelect">
            <div
              className="registerType"
              onClick={() => this.setState({ type: 'individual' })}
            >
              <div className="typeIcon individual" />
              <span>I’m joining as an</span>
              <label>Individual</label>
              <button>Join</button>
            </div>
            <div
              className="registerType"
              onClick={() => this.setState({ type: 'organisation' })}
            >
              <div className="typeIcon organisation" />
              <span>I’m joining as an</span>
              <label>Organisation</label>
              <button>Join</button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderInput = (key, type = 'text', placeholder = '') => {
    return (
      <TextInput
        type={type}
        value={this.state.data[key]}
        placeholder={placeholder}
        error={this.state.errors[key]}
        onChange={(value) =>
          this.setState({
            data: {
              ...this.state.data,
              [key]: value,
            },
          })
        }
      />
    );
  };

  renderIndividualForm = () => {
    return (
      <div className="registerForm">
        <div
          className="backButton"
          onClick={() => this.setState({ type: null })}
        >
          Back
        </div>
        <div className="formContent">
          <div className="formTitle">Create your Account</div>
          <label>Your Personal Email</label>
          {this.renderInput('email', 'email', 'yourname@email.com')}
          <label>Create Password (min. 8 characters)</label>
          {this.renderInput('password', 'password')}
          <label>Confirm Password</label>
          {this.renderInput('confirmPassword', 'password')}
          <button onClick={() => this.saveUser(this.state.type)}>
            Continue
          </button>
          <p>
            By Continuing, I agree to the{' '}
            <span onClick={() => window.open('/privacy', '_blank')}>
              Privacy Policy
            </span>{' '}
            and{' '}
            <span onClick={() => window.open('/terms', '_blank')}>
              Terms of Use
            </span>
            .
          </p>
        </div>
      </div>
    );
  };

  renderOrganisationForm = () => {
    return (
      <div className="registerForm">
        <div
          className="backButton"
          onClick={() => this.setState({ type: null })}
        >
          Back
        </div>
        <div className="formContent">
          <div className="formTitle">
            Create an Account for your Organisation
          </div>
          <label>Your Organisation Email</label>
          {this.renderInput('email', 'email', 'yourname@organisation.com')}
          <label>Create Password (min. 8 characters)</label>
          {this.renderInput('password', 'password')}
          <label>Confirm Password</label>
          {this.renderInput('confirmPassword', 'password')}
          <button onClick={() => this.saveUser(this.state.type)}>
            Continue
          </button>
          <p>
            By Continuing, I agree to the{' '}
            <span onClick={() => window.open('/privacy', '_blank')}>
              Privacy Policy
            </span>{' '}
            and{' '}
            <span onClick={() => window.open('/terms', '_blank')}>
              Terms of Use
            </span>
            .
          </p>
        </div>
      </div>
    );
  };

  render = () => {
    return (
      <div className="registerView">
        <Helmet>
          <title>The SDG Co</title>
          <meta name="description" content="The SDG Co" />
          <meta name="keywords" content="social" />
          <meta property="og:url" content="https://thesdg.co" />
          <meta property="og:site_name" content="The SDG Co" />
          <meta property="og:title" content="The SDG Co" />
          <meta property="og:image" content="https://thesdg.co/logo.png" />
          <meta property="og:description" content="The SDG Co" />
          <meta property="og:type" content="website" />
          <meta name="image" content="https://thesdg.co/logo.png" />
        </Helmet>
        <div
          className="registerLogo"
          onClick={() => this.props.history.push('/')}
        />
        {this.state.type === 'individual'
          ? this.renderIndividualForm()
          : this.state.type === 'organisation'
          ? this.renderOrganisationForm()
          : this.renderTypeSelect()}
      </div>
    );
  };
}

export default withRouter(RegisterView);
