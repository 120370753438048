import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import EditProfileView from './EditProfileView';
import EditInterestsView from './EditInterestsView';
import EditSolutionsView from './EditSolutionsView';
import EditStaffView from './EditStaffView';
import ManageCardView from './ManageCardView';
import ChangePasswordView from './ChangePasswordView';
import StepperView from './common/StepperView';

import { triggerEvent } from '../helpers/global.js';

import '../sass/components/WizardView.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
  businessProfile: store.data.businessProfile,
});

class WizardView extends React.Component {
  constructor(props) {
    super(props);
    const steps = this.initSteps();
    this.state = {
      steps: this.getFilteredSteps(steps),
      step: steps[0]?.key,
      organizationId: this.paramOrganizationId(),
    };
  }

  ownProfile = () => {
    const { businessProfile, user } = this.props;
    return businessProfile ? businessProfile : user;
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      const steps = this.initSteps();
      this.setState({
        steps: this.getFilteredSteps(steps),
        step: steps[0]?.key,
        organizationId: this.paramOrganizationId(),
      });
    }
  };

  initSteps = () => {
    const subscriptionEnabled = !!this.ownProfile() ? this.ownProfile().subscription_enabled : true;

    return this.paramRole() === 'individual'
      ? [
          { key: 'profile', title: 'About You' },
          { key: 'interests', title: 'UN SDGs & Interests' },
          {
            key: 'payment',
            title: 'Payment Setting',
            hiddenCondition: !subscriptionEnabled,
            editOnly: true,
          },
          {
            key: 'account_settings',
            title: 'Account Setting',
            hiddenCondition: false,
            editOnly: true,
          },
          { key: 'complete', title: 'Complete!', wizard: true },
        ]
      : [
          { key: 'profile', title: 'General Information' },
          { key: 'interests', title: 'UN SDGs & Interests' },
          { key: 'solutions', title: 'Solutions Provided' },
          { key: 'staff', title: 'Staff', editOnly: true },
          {
            key: 'payment',
            title: 'Payment Setting',
            editOnly: true,
            hiddenCondition: !!subscriptionEnabled ? this.props.businessProfile?.user_id !== this.props.user.id : true
          },
          { key: 'complete', title: 'Complete!', wizard: true },
        ];
  };

  getFilteredSteps = (steps) => {
    if (!this.isWizard()) {
      steps = steps.filter((step) => !step.wizard && !step.hiddenCondition);
    } else {
      steps = steps.filter((step) => !step.editOnly);
    }
    return steps;
  };

  handleOrganization = (value) => {
    this.setState({ organizationId: value });
  };

  isWizard = () => {
    return this.props.match.path.includes('wizard');
  };

  paramOrganizationId = () => {
    if (this.props.match.params.business_id) {
      return this.props.match.params.business_id;
    }
  };

  paramRole = () => {
    const { params } = this.props.match;
    if (params.role) {
      return params.role;
    } else if (params.user_identifier) {
      return 'individual';
    } else if (params.business_id) {
      return 'organization';
    }
  };

  onSave = () => {
    if (!this.isWizard()) {
      triggerEvent('showSnackbar', [
        { text: 'Profile has been updated successfully.', type: 'success' },
      ]);
      return;
    }
    const index = this.state.steps.findIndex((i) => i.key === this.state.step);
    const nextStep = this.state.steps[index + 1];
    if (nextStep) {
      this.setState({ step: nextStep.key });
    }
  };

  openProfilePage = () => {
    const userId = this.props.user.slug || this.props.user.id;
    return this.paramRole() === 'individual'
      ? this.props.history.push(`/user/${userId}`)
      : this.props.history.push(`/business/${this.state.organizationId}`);
  };

  renderCompleteStep = () => {
    return (
      <div className="completeStep">
        <div className="completeImage" />
        <div className="completeTitle">
          {this.paramRole() === 'individual'
            ? 'Awesome! Your Profile is ready.'
            : 'Awesome. Your Organisation Profile is ready.'}
        </div>
        <div className="completeSubtitle">
          Check out your profile and you can change these information anytime
          later.
        </div>
        <button onClick={this.openProfilePage}>
          {this.paramRole() === 'individual'
            ? 'See your Profile'
            : 'See Organisation Profile'}
        </button>
      </div>
    );
  };

  renderStepContent = () => {
    if (this.state.step === 'profile') {
      return (
        <EditProfileView
          wizard={this.isWizard()}
          onSave={this.onSave}
          role={this.paramRole()}
          organizationId={this.state.organizationId}
          handleOrganization={this.handleOrganization}
        />
      );
    } else if (this.state.step === 'interests') {
      return (
        <EditInterestsView
          wizard={this.isWizard()}
          onSave={this.onSave}
          role={this.paramRole()}
          organizationId={this.state.organizationId}
        />
      );
    } else if (this.state.step === 'solutions') {
      return (
        <EditSolutionsView
          wizard={this.isWizard()}
          onSave={this.onSave}
          organizationId={this.state.organizationId}
        />
      );
    } else if (this.state.step === 'staff') {
      return (
        <EditStaffView
          onSave={this.onSave}
          organizationId={this.state.organizationId}
        />
      );
    } else if (this.state.step === 'complete') {
      return this.renderCompleteStep();
    } else if (this.state.step === 'payment') {
      return <ManageCardView />;
    } else if (this.state.step === 'account_settings') {
      return <ChangePasswordView embedded />;
    }
  };

  render = () => {
    return (
      <div className="wizardView">
        <Helmet>
          <title>The SDG Co</title>
          <meta name="description" content="The SDG Co" />
          <meta name="keywords" content="social" />
          <meta property="og:url" content="https://thesdg.co" />
          <meta property="og:site_name" content="The SDG Co" />
          <meta property="og:title" content="The SDG Co" />
          <meta property="og:image" content="https://thesdg.co/logo.png" />
          <meta property="og:description" content="The SDG Co" />
          <meta property="og:type" content="website" />
          <meta name="image" content="https://thesdg.co/logo.png" />
        </Helmet>
        <StepperView
          steps={this.state.steps}
          step={this.state.step}
          onStepChange={(step) => this.setState({ step })}
          centered={this.paramRole() === 'individual'}
        />
        {this.renderStepContent()}
      </div>
    );
  };
}

export default connect(mapStoreToProps)(withRouter(WizardView));
