import React, { useEffect, useState, useRef } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { Modal, Button, Input, Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { isEmpty, omit } from 'ramda';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import Icon, { IconSet } from '../../components/common/Icon';
import companiesFormatter, {
  keySeparator,
  modelSeparator,
  othersModelSeparator,
  loadCompaniesBase64Logos,
} from '../../helpers/companiesFormatter';
import { sendRequest } from '../../helpers/RequestDispatcher';
import { triggerEvent } from '../../helpers/global';
import { loadOptions } from '../../helpers/market_map';
import CardList from './shared/CardList';
import HeaderMeta from '../common/HeaderMeta';
import Information from './shared/Information';
import Breadcrumbs from './shared/Breadcrumbs';
import Dropdown from './shared/Dropdown';
import CompanyForm, { RemoteSubmit } from './shared/CompanyForm';
import './../../sass/components/market_map/MarketMapCompanyListView.scss';

const SelectedText = styled.div`
  position: relative;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 3px;
  font-size: 14px;
  line-height: 30px;
  color: #333333;
  padding: 10px 20px 10px 10px;
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media all and (min-width: 768px) {
    width: 200px;
  }

  &:after {
    content: url(/images/market_map/chevron-down-gray.svg);
    position: absolute;
    top: 9px;
    right: 10px;
  }
`;

const ShareExportModalHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #666666;
  padding: 15px 0 18px 0;
  border-bottom: 1px solid #eeeeee;
`;
const ShareExportModal = styled(Modal)`
  width: 400px !important;

  @media all and (min-width: 768px) {
    width: 500px !important;
  }
`;
const ShareExportModalContent = styled(Modal.Content)`
  padding: 0 !important;
`;
const ExportContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 10px 0 15px 0;
  border-bottom: 1px solid #eeeeee;

  @media all and (min-width: 768px) {
    padding: 18px 0 30px 0;
  }
`;
const ExportButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 3px;

  &:hover {
    background-color: #eee;
  }

  .export-img {
    width: 45px;
    height: 45px;
    margin-bottom: 3px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &.export-jpg {
      background-image: url(/images/market_map/export-jpg.svg);
    }

    &.export-pdf {
      background-image: url(/images/market_map/export-pdf.svg);
    }

    &.export-csv {
      background-image: url(/images/market_map/export-csv.svg);
    }
  }

  .export-text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #666666;
  }
`;
const ShareWrapper = styled.div`
  padding: 23px 20px 32px 20px;

  @media all and (min-width: 768px) {
    padding: 23px 68px 32px 68px;
  }
`;
const ShareContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  @media all and (min-width: 768px) {
    margin-bottom: 32px;
  }
`;
const ShareSocialButton = styled.a`
  width: 45px;
  height: 45px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  cursor: pointer;

  &.share-facebook {
    background-image: url(/images/market_map/share-facebook.svg);
  }

  &.share-linkedin {
    background-image: url(/images/market_map/share-linkedin.svg);
  }

  &.share-twitter {
    background-image: url(/images/market_map/share-twitter.svg);
  }

  &.share-whatsapp {
    background-image: url(/images/market_map/share-whatsapp.svg);
  }

  &.share-telegram {
    background-image: url(/images/market_map/share-telegram.svg);
  }
`;
const ShareInput = styled(Input)`
  background: #f5f5f5;
  border: 1px solid #cccccc;
  border-radius: 3px;

  input {
    border: none !important;
    background: #f5f5f5 !important;
    font-family: 'Open Sans' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px !important;
    color: #666666 !important;
  }

  .ui.button {
    background: transparent;
    text-transform: uppercase;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #f26e26;
    padding-left: 0;
  }
`;

function MarketMapCompanyListView(props) {
  const [optionsGoal, setOptionsGoal] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [openDropdownGoal, setOpenDropdownGoal] = useState(false);
  const [optionsMarketMap, setOptionsMarketMap] = useState([]);
  const [selectedMarketMap, setSelectedMarketMap] = useState(null);
  const [openDropdownMarketMap, setOpenDropdownMarketMap] = useState(false);
  const [companies, setCompanies] = useState({});
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [information, setInformation] = useState(null);
  const [loadingInformation, setLoadingInformation] = useState(false);
  const [valueChainPositions, setValueChainPositions] = useState([]);
  const [selectedValueChainPosition, setSelectedValueChainPosition] =
    useState(null);
  const [valueChainCategories, setValueChainCategories] = useState([]);
  const [selectedValueChainCategory, setSelectedValueChainCategory] =
    useState(null);
  const [openDropdownValueChainCategory, setOpenDropdownValueChainCategory] =
    useState(false);
  const [openDropdownValueChain, setOpenDropdownValueChain] = useState(false);
  const params = useParams();
  const [expertList, setExpertList] = useState([]);
  const [tabIndex, setTabindex] = useState(0);
  const [openAddCompany, setOpenAddCompany] = useState(false);
  const [initialValuesCompany, setInitialValuesCompany] = useState({
    name: '',
    url: '',
    location: '',
    company_type_id: null,
    goal_id: null,
    market_map_id: null,
    value_chain_id: null,
    value_chain_type_id: null,
    description: '',
    funding_round: '',
    funding_amount: '',
    market_models: [],
    other_market_model: '',
    business_models: [],
    other_business_model: '',
    logo_base64: '',
    logo_filename: '',
  });
  const [isSubmitCompany, setIsSubmitCompany] = useState(false);
  const [savingAddCompany, setSavingAddCompany] = useState(false);
  const [shareLink, setShareLink] = useState('');
  const [openShareModal, setOpenShareModal] = useState(false);
  const [openCopyLinkPopup, setOpenCopyLinkPopup] = useState(false);
  const printRef = useRef();
  const [loadingBase64Logos, setLoadingBase64Logos] = useState(false);
  const customListStyle = `
    padding: 7px;
    right: 0;
    left: unset;
    top: 77px;

    @media all and (min-width: 768px) {
      min-width: 200px;
      left: 0;
      right: unset;
    }

    @media all and (min-width: 1024px) {
      left: unset;
      right: 0;
    }
`;
  const customItemStyle = `
    padding: 10px;
    border-radius: 3px;
  `;

  const loadCompanies = () => {
    setLoadingBase64Logos(false);
    setLoadingCompanies(true);
    sendRequest({
      node: true,
      method: 'companies',
      type: 'GET',
      data: {
        market_map_id: selectedMarketMap.value,
        order: 'id',
        direction: 'asc',
      },
      success: (data) => {
        const companiesFormatted = companiesFormatter(data.objects);
        setCompanies(companiesFormatted);
        setLoadingCompanies(false);

        if (!isEmpty(companiesFormatted)) {
          const positions = Object.keys(companiesFormatted).map(
            (chainPosition) => {
              const arrString = chainPosition.split(keySeparator);
              const text = arrString[0];
              const id = +arrString[1];

              return {
                key: chainPosition,
                text,
                value: id,
              };
            }
          );
          setValueChainPositions(positions);
          setSelectedValueChainPosition(positions[0]);

          const categories = Object.keys(companiesFormatted[positions[0].key]);
          if (!isEmpty(categories)) {
            const newCategories = categories.map((value) => {
              const arrString = value.split(keySeparator);
              const text = arrString[0];
              const id = +arrString[1];

              return {
                key: value,
                text,
                value: id,
              };
            });
            setValueChainCategories(newCategories);
          }
        }
      },
      error: (err) => {
        triggerEvent('showSnackbar', [{ text: err.message, type: 'error' }]);
        setLoadingCompanies(false);
      },
    });
  };

  const loadInformation = () => {
    setLoadingInformation(true);
    sendRequest({
      node: true,
      method: `informations/${selectedGoal.value}/${selectedMarketMap.value}`,
      type: 'GET',
      success: (data) => {
        const { market_map_experts } = data;
        if (market_map_experts && market_map_experts.length) {
          setExpertList(
            market_map_experts.map((value) => {
              const { id, name, position, linkedin_url, profile_picture } =
                value;
              return {
                id,
                ...(profile_picture && { imageSrc: profile_picture }),
                imgRounded: true,
                title: name,
                ...(position && { subtitle: position }),
                ...(linkedin_url && {
                  link: linkedin_url,
                  linkText: 'View Profile',
                  linkNewTab: true,
                  linkTo: 'remote',
                }),
              };
            })
          );
        }
        setInformation(data);
        setLoadingInformation(false);
      },
      error: (err) => {
        // triggerEvent('showSnackbar', [{ text: err.message, type: 'error' }]);
        setExpertList([]);
        setInformation(null);
        setLoadingInformation(false);
      },
    });
  };

  const handleOnSelectGoal = (goal) => {
    if (!selectedGoal || selectedGoal.value !== goal.value) {
      props.history.push(`/market-maps/${goal.key}`);
    }
    setOpenDropdownGoal(false);
  };

  const handleOnSelectMarketMap = (marketMap) => {
    if (!selectedMarketMap || selectedMarketMap.value !== marketMap.value) {
      props.history.push(`/market-maps/${params.goal_slug}/${marketMap.key}`);
      setSelectedMarketMap(marketMap);
    }
    setOpenDropdownMarketMap(false);
  };

  const handleOnSelectValueChainPosition = (valueChainPosition) => {
    setSelectedValueChainPosition(valueChainPosition);
    setSelectedValueChainCategory(null);
    setOpenDropdownValueChain(false);
  };

  const handleOnSelectValueChainCategory = (valueChainCategory) => {
    setSelectedValueChainCategory(
      valueChainCategory.value === 'All' ? null : valueChainCategory
    );
    setOpenDropdownValueChainCategory(false);
  };

  const handleOnAddCompany = (value_chain_type_id, value_chain_id) => {
    setInitialValuesCompany({
      ...initialValuesCompany,
      goal_id: selectedGoal.value,
      market_map_id: selectedMarketMap.value,
      value_chain_id,
      value_chain_type_id,
    });
    setOpenAddCompany(true);
  };

  const handleCloseModalCompany = () => {
    setOpenAddCompany(false);
    setIsSubmitCompany(false);
  };

  const handleRemoteSubmitFormCompany = () => {
    setIsSubmitCompany(true);
  };

  const handleSubmitAddCompany = (values) => {
    setSavingAddCompany(true);
    const market_models = values.market_models
      .map((value) =>
        value === 'others'
          ? [value, values.other_market_model].join(othersModelSeparator)
          : value
      )
      .join(modelSeparator);
    const business_models = values.business_models
      .map((value) =>
        value === 'others'
          ? [value, values.other_business_model].join(othersModelSeparator)
          : value
      )
      .join(modelSeparator);
    sendRequest({
      node: true,
      method: 'company-requests',
      type: 'POST',
      data: {
        ...omit(
          [
            'goal_id',
            'other_market_model',
            'other_business_model',
            'value_chain_id',
            'company_type_name',
            'goal_name',
            'market_map_name',
            'value_chain_name',
            'value_chain_type_name',
          ],
          values
        ),
        market_models,
        business_models,
      },
      success: (_) => {
        triggerEvent('showSnackbar', [
          { text: 'Add Company request successful', type: 'success' },
        ]);
        setSavingAddCompany(false);
        setOpenAddCompany(false);
      },
      error: (err) => {
        setSavingAddCompany(false);
        triggerEvent('showSnackbar', [{ text: err.message, type: 'error' }]);
      },
    });
  };

  const handleOpenShareExport = () => {
    setOpenShareModal(true);
  };

  const handleCloseShareExport = () => {
    setOpenShareModal(false);
  };

  const handleShareLinkCopy = () => {
    navigator.clipboard.writeText(shareLink);
    setOpenCopyLinkPopup(true);
    setTimeout(() => {
      setOpenCopyLinkPopup(false);
    }, 1000);
  };

  const loadNewCompanyLogos = () => {
    setLoadingBase64Logos(true);
    const processNewLogos = async () => {
      await loadCompaniesBase64Logos({
        companies,
        setter: setCompanies,
      });
    };
    processNewLogos();
  };

  const handleExportJpg = () => {
    const processExport = async () => {
      const element = printRef.current;
      const canvas = await html2canvas(element, {
        allowTaint: true,
        useCORS: true,
      });
      const data = canvas.toDataURL('image/jpg');
      const link = document.createElement('a');

      if (typeof link.download === 'string') {
        link.href = data;
        link.download = 'image.jpg';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    };
    processExport();
  };

  const handleExportPdf = () => {
    const processExport = async () => {
      const element = printRef.current;
      const canvas = await html2canvas(element, {
        allowTaint: true,
        useCORS: true,
      });
      const data = canvas.toDataURL('image/png');

      const pdf = new jsPDF();
      const imgProperties = pdf.getImageProperties(data);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

      pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('print.pdf');
    };
    processExport();
  };

  useEffect(() => {
    loadOptions({
      route: 'goals',
      setter: setOptionsGoal,
      isLocalStorage: true,
    });
    setShareLink(`${window.location.host}${window.location.pathname}`);
  }, []);

  useEffect(() => {
    if (optionsGoal.length && params.goal_slug) {
      const findOption = optionsGoal.find(
        (value) => value.key === params.goal_slug
      );
      if (findOption) {
        setSelectedGoal(findOption);
        loadOptions({
          route: 'market-maps',
          setter: setOptionsMarketMap,
          additionalParams: {
            goal_id: +findOption.value,
          },
        });
      }
    }
  }, [optionsGoal, params.goal_slug]);

  useEffect(() => {
    if (optionsMarketMap.length && params.market_map_slug) {
      const findOption = optionsMarketMap.find(
        (value) => value.key === params.market_map_slug
      );
      if (findOption) {
        setSelectedMarketMap(findOption);
      }
    }
  }, [optionsMarketMap, params.market_map_slug]);

  useEffect(() => {
    if (selectedGoal && selectedMarketMap) {
      loadCompanies();
      loadInformation();
    }
  }, [selectedGoal, selectedMarketMap]);

  useEffect(() => {
    if (selectedValueChainPosition) {
      const categories = Object.keys(companies[selectedValueChainPosition.key]);
      if (!isEmpty(categories)) {
        const newCategories = categories.map((value) => {
          const arrString = value.split(keySeparator);
          const text = arrString[0];
          const id = +arrString[1];

          return {
            key: value,
            text,
            value: id,
          };
        });
        setValueChainCategories(newCategories);
      }
    }
  }, [selectedValueChainPosition]);

  useEffect(() => {
    if (!isEmpty(companies) && !loadingBase64Logos) {
      loadNewCompanyLogos();
    }
  }, [companies, loadingBase64Logos]);

  return (
    <>
      <HeaderMeta />
      <div className="marketMapView" ref={printRef}>
        <div className="header market-map-header">
          {selectedMarketMap ? selectedMarketMap.text : ''}
        </div>
        <Breadcrumbs
          items={[
            {
              setOpen: setOpenDropdownGoal,
              text: selectedGoal ? selectedGoal.text : 'Select SDG',
              open: openDropdownGoal,
              options: optionsGoal,
              selectHandler: handleOnSelectGoal,
            },
            {
              setOpen: setOpenDropdownMarketMap,
              text: selectedMarketMap
                ? selectedMarketMap.text
                : 'Select Market Map',
              open: openDropdownMarketMap,
              options: optionsMarketMap,
              selectHandler: handleOnSelectMarketMap,
            },
          ]}
        />
        <section>
          {selectedMarketMap && (
            <div className="sdg-tabbed">
              <div className="sdg-tab-list">
                <div
                  className={`sdg-tab ${tabIndex === 0 ? 'active' : ''}`}
                  onClick={() => setTabindex(0)}
                >
                  Companies
                </div>
                <div
                  className={`sdg-tab ${tabIndex === 1 ? 'active' : ''}`}
                  onClick={() => setTabindex(1)}
                >
                  Information
                </div>
              </div>
              <div className="sdg-tab-share">
                <button onClick={() => handleOpenShareExport()}>
                  <Icon
                    icon={IconSet.MarketMap.Share}
                    className="sdg-share-icon"
                  />
                  Share
                </button>
              </div>
            </div>
          )}
          {tabIndex === 0 && (
            <>
              {!loadingCompanies && isEmpty(companies) && (
                <CardList
                  title=""
                  add
                  addText="Add Company"
                  addHandler={() => handleOnAddCompany(null, null)}
                />
              )}
              {!loadingCompanies && !isEmpty(companies) && (
                <>
                  {/* <div className="sdg-experts-text">5 Experts</div> */}
                  <div className="sdg-value-chain-container">
                    <div className="sdg-value-chain-list">
                      {valueChainPositions.map((value) => (
                        <button
                          key={value.key}
                          className={`sdg-value-chain-button ${
                            selectedValueChainPosition &&
                            value.value === selectedValueChainPosition.value
                              ? 'active'
                              : ''
                          }`}
                          onClick={() =>
                            handleOnSelectValueChainPosition(value)
                          }
                        >
                          {value.text}
                        </button>
                      ))}
                    </div>
                    {selectedValueChainPosition && (
                      <div className="sdg-value-chain-dropdown-container sdg-value-chain">
                        <div className="sdg-dropdown-wrapper">
                          <label>Filter by Position</label>
                          <div className="sdg-dropdown-select-container">
                            <SelectedText
                              onClick={() => setOpenDropdownValueChain(true)}
                            >
                              {selectedValueChainPosition.text}
                            </SelectedText>
                            <Dropdown
                              open={openDropdownValueChain}
                              setOpen={setOpenDropdownValueChain}
                              options={[...valueChainPositions]}
                              selectHandler={handleOnSelectValueChainPosition}
                              customListStyle={customListStyle}
                              customItemStyle={customItemStyle}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="sdg-value-chain-dropdown-container sdg-value-chain-category">
                      <div className="sdg-dropdown-wrapper">
                        <label>Filter by segments</label>
                        <div className="sdg-dropdown-select-container">
                          <SelectedText
                            onClick={() =>
                              setOpenDropdownValueChainCategory(true)
                            }
                          >
                            {selectedValueChainCategory
                              ? selectedValueChainCategory.text
                              : 'All'}
                          </SelectedText>
                          <Dropdown
                            open={openDropdownValueChainCategory}
                            setOpen={setOpenDropdownValueChainCategory}
                            options={[
                              { key: 'all', text: 'All', value: 'All' },
                              ...valueChainCategories,
                            ]}
                            selectHandler={handleOnSelectValueChainCategory}
                            customListStyle={customListStyle}
                            customItemStyle={customItemStyle}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {selectedValueChainPosition &&
                    Object.keys(companies[selectedValueChainPosition.key])
                      .filter((chainCategory) =>
                        !selectedValueChainCategory
                          ? true
                          : selectedValueChainCategory.key === chainCategory
                      )
                      .map((chainCategory, indexCat) => {
                        const chainCategoryArr =
                          chainCategory.split(keySeparator);
                        const chainCategoryText = chainCategoryArr[0];
                        const chainCategoryid = +chainCategoryArr[1];

                        const list = companies[selectedValueChainPosition.key][
                          chainCategory
                        ].map((company) => {
                          const { id, name, logo, slug } = company;
                          // const logoObj = logo ? JSON.parse(logo) : logo;

                          return {
                            id,
                            title: name,
                            // ...(logoObj && { imageSrc: logoObj.Location }),
                            ...(logo && { imageSrc: logo }),
                            link: `/market-maps/companies/${slug}`,
                            linkText: 'View Company',
                            linkNewTab: true,
                            linkTo: 'remote',
                          };
                        });
                        return (
                          <CardList
                            key={indexCat}
                            title={chainCategoryText}
                            list={list}
                            add
                            addText="Add Company"
                            addHandler={() =>
                              handleOnAddCompany(
                                chainCategoryid,
                                selectedValueChainPosition.value
                              )
                            }
                          />
                        );
                      })}
                </>
              )}
            </>
          )}
          {tabIndex === 1 && (
            <>
              {!loadingInformation && !information && <CardList title="" />}
              {!loadingInformation && information && (
                <Information
                  information={information}
                  expertList={expertList}
                />
              )}
            </>
          )}
        </section>
      </div>
      <Modal
        onClose={() => handleCloseModalCompany()}
        onOpen={() => setOpenAddCompany(true)}
        open={openAddCompany}
        size="large"
        closeOnDimmerClick={false}
        closeIcon
      >
        <Modal.Header size="medium">Add Company</Modal.Header>
        <Modal.Content scrolling>
          <CompanyForm
            formValues={initialValuesCompany}
            onSubmitHandler={handleSubmitAddCompany}
            renderRemoteSubmit={() => (
              <RemoteSubmit
                submit={isSubmitCompany}
                setSubmit={setIsSubmitCompany}
              />
            )}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => handleCloseModalCompany()}
            disabled={savingAddCompany}
          >
            Close
          </Button>
          <Button
            positive
            onClick={() => handleRemoteSubmitFormCompany()}
            disabled={savingAddCompany}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
      <ShareExportModal
        open={openShareModal}
        closeIcon
        onClose={() => handleCloseShareExport()}
      >
        <ShareExportModalHeader>
          <object
            type="image/svg+xml"
            height="14"
            width="13"
            data="/images/market_map/share-dark.svg"
            style={{ marginRight: 10 }}
          ></object>
          Share/Export
        </ShareExportModalHeader>
        <ShareExportModalContent>
          <ExportContainer>
            <ExportButton>
              <div
                className="export-img export-jpg"
                onClick={() => handleExportJpg()}
              ></div>
              Export as JPG
            </ExportButton>
            <ExportButton>
              <div
                className="export-img export-pdf"
                onClick={() => handleExportPdf()}
              ></div>
              Export as PDF
            </ExportButton>
            <ExportButton>
              <div className="export-img export-csv"></div>
              Export as CSV
            </ExportButton>
          </ExportContainer>
          <ShareWrapper>
            <ShareContainer>
              <ShareSocialButton
                className="share-facebook"
                href={`https://www.facebook.com/sharer/sharer.php?u=${shareLink}`}
                target="_blank"
                rel="noopener noreferrer"
              />
              <ShareSocialButton
                className="share-linkedin"
                href={`https://www.linkedin.com/shareArticle?url=${shareLink}`}
                target="_blank"
                rel="noopener noreferrer"
              />
              <ShareSocialButton
                className="share-twitter"
                href={`https://twitter.com/intent/tweet?text=${shareLink}`}
                target="_blank"
                rel="noopener noreferrer"
              />
              <ShareSocialButton
                className="share-whatsapp"
                href={`https://api.whatsapp.com/send?text=${shareLink}`}
                target="_blank"
                rel="noopener noreferrer"
              />
              <ShareSocialButton
                className="share-telegram"
                href={`https://t.me/share/url?url=${shareLink}`}
                target="_blank"
                rel="noopener noreferrer"
              />
            </ShareContainer>
            <ShareInput
              defaultValue={shareLink}
              fluid
              action={
                <Popup
                  trigger={
                    <Button
                      content="Copy"
                      onClick={() => handleShareLinkCopy()}
                    />
                  }
                  content="Copied"
                  on="click"
                  open={openCopyLinkPopup}
                  position="right center"
                  size="mini"
                  inverted
                />
              }
              input={<input readOnly />}
            />
          </ShareWrapper>
        </ShareExportModalContent>
      </ShareExportModal>
    </>
  );
}

export default withRouter(MarketMapCompanyListView);
