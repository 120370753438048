import React from 'react';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { sendRequest } from '../helpers/RequestDispatcher.js';
import Storage from '../helpers/Storage.js';
import HeaderMeta from './common/HeaderMeta.js';

import '../sass/components/HomeView.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
  webpSupported: store.setup.webpSupported,
  setup: store.setup,
});

class HomeView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      goals: [],
      webpSupported: true,
      counters: {
        connections: 1000,
        profiles: 450,
        countries: 60,
      },
    };
  }

  componentDidMount = () => {
    this.getCommonData();
    this.getCounters();
    if (typeof window !== 'undefined') {
      this.getSetting();
    }
  };

  getCounters = () => {
    sendRequest({
      method: 'common_data/counters',
      type: 'GET',
      success: (data) => {
        this.setState((prevState) => ({
          counters: {
            connections:
              data.connections < 1000
                ? 1000 + data.connections
                : data.connections,
            profiles: data.profiles < 450 ? 450 + data.profiles : data.profiles,
            countries:
              data.countries < 60 ? 60 + data.countries : data.countries,
          },
        }));
      },
      error: (data) => {},
    });
  };

  getCommonData = () => {
    sendRequest({
      method: 'common_data/goals',
      type: 'GET',
      success: (data) => {
        this.setState({ goals: data });
      },
      error: (data) => {},
    });
  };

  getSetting = () => {
    this.setState({ webpSupported: Storage.getSetup('webpSupported') });
  };

  onJoin = () => {
    this.props.history.push('/register');
  };

  thousandsSeparator = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  handleLinkClick = (link, external = false) => {
    if (external) {
      window.open(link, '_blank');
    } else {
      this.props.history.push(link);
    }
  };

  renderFooter = () => {
    return (
      <div className="homeFooter">
        <div className="footerContent">
          <div className="leftLinks">
            <div
              className="footerLink sdg"
              onClick={() => this.handleLinkClick('/')}
            />
            <div
              className="footerLink twitter"
              onClick={() =>
                this.handleLinkClick('https://twitter.com/ecobusinesscom', true)
              }
            />
            <div
              className="footerLink facebook"
              onClick={() =>
                this.handleLinkClick(
                  'https://facebook.com/EcoBusinessMedia',
                  true
                )
              }
            />
            <div
              className="footerLink instagram"
              onClick={() =>
                this.handleLinkClick(
                  'https://instagram.com/ecobusinesscom',
                  true
                )
              }
            />
          </div>
          <div className="copyright">
            © Eco-Business 2009 - {new Date().getFullYear()}. All rights
            reserved.
          </div>
          <div className="rightLinks">
            <div className="footerLink">
              <a
                href="https://faq.thesdg.co/"
                target="_blank"
                className="faqLink"
              >
                FAQ
              </a>
            </div>
            <div
              className="footerLink"
              onClick={() => this.handleLinkClick('/privacy')}
            >
              Privacy Policy
            </div>
            <div
              className="footerLink"
              onClick={() => this.handleLinkClick('/terms')}
            >
              Terms of Use
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderWelcomeBlock = () => {
    const user = this.props.user;
    return (
      <div className="welcomeBlock">
        <div className="welcomeBackground" />
        <div className="welcomeContent">
          <div className="welcomeHeader">
            <div className="welcomeTitle">
              Transforming Innovation for&nbsp;Sustainability
            </div>
            <div className="welcomePretitle">An initiative by</div>
          </div>
          <div className="welcomeInfo">
            <p>
              The SDG Co is an ecosystem that enables sustainability innovations
              to reach the markets and achieve scale.
            </p>
            {user ? (
              <button onClick={() => this.props.history.push('/profile')}>
                Join the Ecosystem
              </button>
            ) : (
              <button onClick={this.onJoin}>Join the Ecosystem</button>
            )}
          </div>
        </div>
        <div className="welcomeAdditional">
          <div className="welcomeSubtitle">How it works</div>
          <div className="welcomeSteps">
            <div className="welcomeStep">
              <div className="stepImage step1" />
              <div className="stepTitle">1. Create your profile</div>
              <div className="stepDescription">
                Create and set up your organisation or individual profile in
                minutes.
              </div>
            </div>
            <div className="welcomeStep">
              <div className="stepImage step2" />
              <div className="stepTitle">
                2. Discover profiles and follow topics
              </div>
              <div className="stepDescription">
                Browse and discover profile based on your preferences. Follow
                topics to stay updated on what’s happening.
              </div>
            </div>
            <div className="welcomeStep">
              <div className="stepImage step3" />
              <div className="stepTitle">3. Start connecting</div>
              <div className="stepDescription">
                Reach out or respond to other profiles and make connections.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderGoalsBlock = () => {
    let tableContent = [{ key: 'sdg', sdg: true }].concat(this.state.goals);
    return (
      <div className="goalsBlock">
        <div className="goalsBackground" />
        <div className="goalsContent">
          <div className="goalsTitle">
            Connecting organisations that are working on UN Sustainable
            Development Goals
          </div>
          <div className="goalsDescription">
            We are the world’s sustainability innovation marketplace, connecting
            start-ups, corporates, governments and investors working on the UN
            Sustainable Development Goals to effectively connect with each
            other.
          </div>
          <div className="goalsTable">
            {tableContent.map((item, i) => (
              <div
                key={i}
                className="goalCell"
                onClick={() => this.props.history.push(`/feed/${item.key}`)}
              >
                {item.sdg ? (
                  <div className="goalCellContent sdg" />
                ) : (
                  <div className={classnames('goalCellContent', item.key)}>
                    <span>
                      {item.key == 'other_sustainability_solutions' ||
                      item.key == 'All'
                        ? ''
                        : i}
                    </span>
                    <label>{item.name}</label>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  renderImpactBlock = () => {
    const { connections, profiles, countries } = this.state.counters;
    return (
      <div className="impactBlock">
        <div className="impactBackground" />
        <div className="impactContent">
          <div className="impactTitle">Our impact</div>
          <div className="impactList">
            <div className="impactItem connections">
              <span>{this.thousandsSeparator(connections)}</span>
              <label>Connections made</label>
            </div>
            <div className="impactItem profiles">
              <span>{this.thousandsSeparator(profiles)}</span>
              <label>Profiles created</label>
            </div>
            <div className="impactItem countries">
              <span>{this.thousandsSeparator(countries)}</span>
              <label>Countries</label>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderPartnetsBlock = () => {
    return (
      <div className="partnersBlock">
        <div className="partnersContent">
          <div className="partnersTitle">Founding Ecosystem Partners</div>
          <div className="partnerList">
            <div
              className="partnetItem go4sdgs"
              onClick={() =>
                window.open(
                  'https://www.greengrowthknowledge.org/initiatives/global-opportunities-sdgs-go4sdgs',
                  '_blank'
                )
              }
            />
            <div
              className="partnetItem livability_challenge"
              onClick={() =>
                window.open(
                  'https://www.theliveabilitychallenge.org/',
                  '_blank'
                )
              }
            />
            <div
              className="partnetItem temasek_foundation"
              onClick={() =>
                window.open(
                  'https://www.temasekfoundation.org.sg/home',
                  '_blank'
                )
              }
            />
            <div
              className="partnetItem ixsa"
              onClick={() => window.open('https://www.ixsa.earth/', '_blank')}
            />
            <div
              className="partnetItem eb_impact"
              onClick={() => window.open('https://www.ebimpact.org/', '_blank')}
            />
            <div
              className="partnetItem fundedhere"
              onClick={() => window.open('https://fundedhere.com/', '_blank')}
            />
            <div
              className="partnetItem tembusu"
              onClick={() =>
                window.open('https://tembusupartners.com/', '_blank')
              }
            />
            <div
              className="partnetItem sg_alliance"
              onClick={() =>
                window.open('https://www.climateactionsg.org/', '_blank')
              }
            />
          </div>
        </div>
      </div>
    );
  };

  renderManifestBlock = () => {
    const flyerItems = [
      'Intentionality validated by measurable and transparent accountability',
      'There is no one size fits all approach',
      'Diversity and inclusion extends beyond people',
      'Symbiotic network effects are exponential',
      'Mental wellness is the backbone for sustained impact',
      'Doing good must be rewarded better than doing bad.',
    ];
    return (
      <div className="manifestBlock">
        <div className="manifestBackground" />
        <div className="manifestContent">
          <div className="manifestIcon" />
          <div className="manifestTitle">
            Innovation <span>x</span> Sustainability
          </div>
          <div className="manifestSubtitle">Manifesto</div>
          <div className="manifestFlyer">
            <div
              className={classnames('flyerBackground', {
                webp: this.state.webpSupported,
              })}
            />
            <div className="flyerContent">
              <p>
                IxSA is a group of practitioners working in the intersection of
                innovation and sustainability who have come together to forge a
                manifesto.
              </p>
              <p>
                We are transforming the way we innovate for sustainability by
                focusing on six core principles that have pronounced
                consequences in achieving impact:
              </p>
              {flyerItems.map((item, i) => (
                <div key={i} className="flyerItem">
                  <span>{i + 1}</span>
                  <p>{item}</p>
                </div>
              ))}
              <p
                className="findOutMore"
                onClick={() => window.open('https://www.ixsa.earth/', '_blank')}
              >
                Find out more here.
              </p>
              <div className="flyerIcon" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderJoinBlock = () => {
    const user = this.props.user;
    return (
      <div className="joinBlock">
        <div className="joinBackground" />
        <div className="joinContent">
          <div className="joinTitle">It starts right here.</div>
          <div className="joinSubtitle">
            Get started by creating your organisation, individual or investor
            profile.
          </div>
          {user ? (
            <button onClick={() => this.props.history.push('/profile')}>
              Join the Ecosystem
            </button>
          ) : (
            <button onClick={this.onJoin}>Join the Ecosystem</button>
          )}
        </div>
      </div>
    );
  };

  render = () => {
    return (
      <div className="homeView">
        <HeaderMeta />
        {this.renderWelcomeBlock()}
        {this.renderGoalsBlock()}
        {this.renderImpactBlock()}
        {this.renderPartnetsBlock()}
        {this.renderManifestBlock()}
        {this.renderJoinBlock()}
        {this.renderFooter()}
      </div>
    );
  };
}

export default connect(mapStoreToProps)(withRouter(HomeView));
