import React from 'react';
import classnames from 'classnames';

import '../../sass/components/common/TargetActivityCard.scss';

export default class TargetActivityCard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  onChange = (value) => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  handleClick = (item) => {
    if (this.props.onClick) {
      this.props.onClick(item);
    }
  }

  render = () => {
    const item = this.props.item;
    const selected = this.props.selected;
    return (
      <div
        key={item.key}
        className={classnames('targetActivityItem', {
          'selected': selected,
          'link': this.props.link,
          'selectable': this.props.selectable && !selected,
        })}
        onClick={() => this.handleClick(item)}
      >
        {selected ?
          <div className='selectedIndicator'>
            <svg width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <line x1='1.06066' y1='5.93934' x2='5.06066' y2='9.93934' stroke='white' strokeWidth='3'/>
              <line x1='16.0607' y1='1.06066' x2='5.06066' y2='12.0607' stroke='white' strokeWidth='3'/>
            </svg>
          </div>
        : null}
        <div className='itemTitle'>{item.title}</div>
        <div className='itemSubtitle'>{item.subtitle}</div>
      </div>
    )
  }

}
