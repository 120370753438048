import React from 'react';
import { Helmet } from 'react-helmet';

import '../sass/components/StaticView.scss';

class PrivacyView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render = () => {
    return (
      <div className="staticView">
        <Helmet>
          <title>The SDG Co</title>
          <meta name="description" content="The SDG Co" />
          <meta name="keywords" content="social" />
          <meta property="og:url" content="https://thesdg.co" />
          <meta property="og:site_name" content="The SDG Co" />
          <meta property="og:title" content="The SDG Co" />
          <meta property="og:image" content="https://thesdg.co/logo.png" />
          <meta property="og:description" content="The SDG Co" />
          <meta property="og:type" content="website" />
          <meta name="image" content="https://thesdg.co/logo.png" />
        </Helmet>
        <h3>Privacy and Data Protection Policy</h3>
        <p>
          The SDG Co respects your privacy and safeguarding your privacy is very
          important to us. This Privacy and Data Protection Policy (this
          “Policy”) applies to The SDG Co collecting, using, disclosing,
          processing, and/or transferring out of Singapore, your personal data
          for one or more of the purposes described in this policy, in
          accordance with the Personal Data Protection Act (“PDPA”).
        </p>
        <ol type="A">
          <li>
            PERSONAL DATA
            <ol type="1">
              <li>
                As used in this Policy:
                <ol type="i" className="romanList">
                  <li>
                    “client” means an individual who (a) has contacted us
                    through any means to find out more about any services we
                    provide, or (b) may, or has, entered into a contract with us
                    for the supply of any services by us;
                  </li>
                  <li>
                    “personal data” means data, whether true or not, about a
                    client who can be identified: (a) from that data; or (b)
                    from that data and other information to which we have or are
                    likely to have access; and
                  </li>
                  <li>
                    “Website” means  
                    <a href="https://www.thesdg.co/">https://www.thesdg.co/</a>.
                  </li>
                </ol>
              </li>
              <li>
                For subscribers, clients and other users of our Website, we may
                collect and process the following data about you:
                <ol type="i" className="romanList">
                  <li>email address;</li>
                  <li>full name;</li>
                  <li>company/organization;</li>
                  <li>industry;</li>
                  <li>job title;</li>
                  <li>country of residence; and</li>
                  <li>topics of interest.</li>
                </ol>
              </li>
              <li>
                Other terms used in this Policy shall have the meanings given to
                them in the PDPA (where the context so permits).
              </li>
            </ol>
          </li>
          <li>
            OTHER TYPES OF DATA WE COLLECT
            <ol type="1">
              <li>
                <p>Log Files</p>
                <p>
                  As with most other websites, we collect and use the data
                  contained in log files. The information in the log files
                  include your IP (internet protocol) address, the browser you
                  used to visit our Website (such as Chrome or Firefox), the
                  time you visited our Website and which pages you visited
                  throughout our Website.
                </p>
              </li>
              <li>
                <p>Cookies</p>
                <p>
                  Our Website uses cookies to distinguish you from other uses of
                  our Website. A cookie is a small file of letters and numbers
                  that we put on your computer or mobile device when you first
                  visit a site or page. The cookie will help our Website to
                  recognise your device the next time you visit. Some cookies
                  are strictly necessary for the operation of our Website,
                  including secure areas to make payment for our client services
                  like{' '}
                  <span>
                    [featured jobs, event listings, press releases and
                    subscription plans]
                  </span>
                  . We use cookies to check if you have a user account, and if
                  you are signed in to your account or not. They allow us to
                  recognise and count the number of visitors and understand how
                  visitors move around our Website. This enables us to improve
                  the Website experience for our users.
                </p>
                <p>
                  We do NOT sell the information collected by cookies, nor do we
                  disclose the information to third parties, except where
                  required by law (for example to government bodies and law
                  enforcement agencies).
                </p>
              </li>
              <li>
                <p>
                  <span>Cookies and Third Party Services</span>
                </p>
                <p>
                  We use a third party service for web analytics. The SDG Co
                  uses Google Analytics to measure visitor statistics. Read more
                  about how Google processes data when visiting our Website. You
                  can Opt out of being tracked by Google Analytics.
                </p>
                <p>
                  We use Google Doubleclick for Small Publishers to serve
                  advertisements via our Website. Google DoubleClick places a
                  cookie on your computer when you are browsing. This cookie may
                  be used to serve ads specific to you and your interests, based
                  on your previous browsing history or geo-location. It does NOT
                  track personal information about you, such as your name, email
                  address, physical address, telephone number, social security
                  numbers, bank account numbers or credit card numbers. You
                  can opt-out of the DoubleClick cookie.
                </p>
              </li>
              <li>
                <p>Cookies and Embedded Content</p>
                <p>
                  To support our Website, we sometimes embed photos and video
                  content from websites such as YouTube, Vimeo, Flickr,
                  Shutterstock or Depositphotos. As a result, when you visit a
                  page containing such content, you may be presented with
                  cookies from these websites. The SDG Co does not control the
                  dissemination of these cookies and you should check the
                  relevant third party's website for more information.
                </p>
              </li>
              <li>
                <p>
                  <span>Cookies and Sharing Tools</span>
                </p>
                <p>
                  You will see embedded ‘share’ buttons on The SDG Co web pages;
                  these enable users to easily share content with their friends
                  through a number of popular social networks. When you click on
                  one of these buttons, a cookie may be set by the service you
                  have chosen to share content through. Again, The SDG Co does
                  not control the dissemination of these cookies.
                </p>
              </li>
            </ol>
          </li>
          <li>
            COLLECTION, USE AND DISCLOSURE OF PERSONAL DATA
            <ol type="1">
              <li>
                We generally do not collect your personal data unless (a) it is
                provided to us voluntarily by you directly or via a third party
                who has been duly authorised by you to disclose your personal
                data to us (your “authorised representative”) after (i) you (or
                your authorised representative) have been notified of the
                purposes for which the data is collected, and (ii) you (or your
                authorised representative) have provided written consent to the
                collection and usage of your personal data for those purposes,
                or (b) collection and use of personal data without consent is
                permitted or required by the PDPA or other laws.
              </li>
              <li>
                We shall seek your consent before collecting any additional
                personal data and before using your personal data for a purpose
                which has not been notified to you (except where permitted or
                authorised by law).
              </li>
              <li>
                We may collect and use your personal data for any or all of the
                following purposes:
                <ol type="i" className="romanList">
                  <li>setting up your account with The SDG Co;</li>
                  <li>
                    performing obligations in the course of or in connection
                    with our provision of the goods and/or services requested by
                    you;
                  </li>
                  <li>verifying your identity;</li>
                  <li>
                    responding to, handling, and processing queries, requests,
                    applications, complaints, and feedback from you;
                  </li>
                  <li>
                    process efficiency in dealing with such activity, and to
                    make improvements to our services;
                  </li>
                  <li>managing your relationship and/or account with us;</li>
                  <li>
                    keeping our records up to date, handling our customer
                    contact efficiently and effectively, working out which of
                    our products and services may interest you and telling you
                    about them;
                  </li>
                  <li>
                    undertaking website personalisation and administration;
                  </li>
                  <li>
                    developing products, services, applications and designs that
                    attract and retain customers;
                  </li>
                  <li>improving customer interaction with our Website;</li>
                  <li>
                    sending you information, promotions and updates including
                    marketing and advertising collaterals in relation to our
                    client services and those of our clients or partner
                    organisations.
                  </li>
                  <li>
                    notifying you about enhancements to our services, such as
                    changes to the Website and new services that may be of
                    interest to you.
                  </li>
                  <li>maintaining network and data security.</li>
                  <li>processing payment or credit transactions;</li>
                  <li>
                    complying with any applicable laws, regulations, codes of
                    practice, guidelines, or rules, or to assist in law
                    enforcement and investigations conducted by any governmental
                    and/or regulatory authority;
                  </li>
                  <li>
                    any other purposes for which you have provided the
                    information;
                  </li>
                  <li>
                    transmitting to any unaffiliated third parties including our
                    third party service providers and agents, and relevant
                    governmental and/or regulatory authorities, whether in
                    Singapore or abroad, for the aforementioned purposes; and
                  </li>
                  <li>
                    any other incidental business purposes related to or in
                    connection with the above.
                  </li>
                </ol>
                <p>
                  For any newsletters, marketing and advertising information
                  sent, we will include instructions on how to unsubscribe
                  through unsubscribe links and preference settings.
                </p>
              </li>
              <li>
                We may disclose your personal data:
                <ol type="i" className="romanList">
                  <li>
                    where such disclosure is required for performing obligations
                    in the course of or in connection with our provision of the
                    goods and services requested by you; or
                  </li>
                  <li>
                    to third party service providers, agents and other
                    organisations we have engaged to perform any of the
                    functions with reference to the above mentioned purposes;
                    and
                  </li>
                  <li>
                    to our overseas offices in the Philippines or China or to
                    any staff employed under The SDG Co, who may be located in
                    or outside of Singapore.
                  </li>
                </ol>
              </li>
              <li>
                We automatically collect log data and device information when
                you access and use the Website, even if you have not created a
                The SDG Co account or logged in. That information includes,
                among other things: details about how you’ve used the Website,
                IP address, access dates and times, hardware and software
                information, device information, device event information and
                cookie data.
              </li>
              <li>
                You should ensure that all personal data submitted to us is
                complete, accurate, true and correct. Any personal information
                we collect from you are solely from the various sign-up options
                on our Website for the different services we provide.
              </li>
            </ol>
          </li>
          <li>
            WITHDRAWING YOUR CONSENT
            <ol type="1">
              <li>
                The consent that you provide for the collection, use and
                disclosure of your personal data will remain valid until such
                time it is being withdrawn by you in writing. You may withdraw
                consent and request us to stop collecting, using and/or
                disclosing your personal data for any or all of the purposes
                listed above by submitting your request in writing or via email
                to our Data Protection Officer at the contact details provided
                below.
              </li>
              <li>
                Upon receipt of your written request to withdraw your consent,
                we will require reasonable time (depending on the complexity of
                the request and its impact on our relationship with you) for
                your request to be processed and for us to notify you of the
                consequences of us acceding to the same, including any legal
                consequences which may affect your rights and liabilities to us.
                In general, we shall seek to process your request within thirty
                (30) business days of receiving it. In the meantime, you may
                still receive marketing and advertising collaterals from us.
              </li>
              <li>
                Please note that withdrawing consent does not affect our right
                to continue to collect, use and disclose personal data where
                such collection, use and disclose without consent is permitted
                or required under applicable laws.
              </li>
            </ol>
          </li>
          <li>
            ACCESS TO AND CORRECTION OF PERSONAL DATA
            <ol type="1">
              <li>
                If you wish to make (a) an access request for access to a copy
                of the personal data which we hold about you or information
                about the ways in which we use or disclose your personal data,
                or (b) a correction request to correct or update any of your
                personal data which we hold about you, you may submit your
                request in writing or via email to our Data Protection Officer
                at the contact details provided below.
              </li>
              <li>
                We will respond to your request as soon as reasonably possible.
                In general, our response will be within fourteen (14) business
                days. Should we not be able to respond to your request within
                thirty (30) days after receiving your request, we will inform
                you in writing within thirty (30) days of the time by which we
                will be able to respond to your request. If we are unable to
                provide you with any personal data or to make a correction
                requested by you, we shall generally inform you of the reasons
                why we are unable to do so (except where we are not required to
                do so under the PDPA).
              </li>
            </ol>
          </li>
          <li>
            PROTECTION OF PERSONAL DATA
            <ol type="1">
              <li>
                To safeguard your personal data from unauthorised access,
                collection, use, disclosure, copying, modification, disposal or
                similar risks, we have introduced appropriate administrative,
                physical and technical measures such as minimised collection of
                personal data, and authentication and access controls (such as
                good password practices, need-to-basis for data disclosure,
                etc.).
              </li>
              <li>
                You should be aware, however, that no method of transmission
                over the Internet or method of electronic storage is completely
                secure. While security cannot be guaranteed, we strive to
                protect the security of your information and are constantly
                reviewing and enhancing our information security measures.
              </li>
            </ol>
          </li>
          <li>
            ACCURACY OF PERSONAL DATA
            <p>
              We generally rely on personal data provided by you (or your
              authorised representative). In order to ensure that your personal
              data is current, complete and accurate, please update us if there
              are changes to your personal data by informing our Data Protection
              Officer in writing or via email at the contact details provided
              below.
            </p>
          </li>
          <li>
            RETENTION OF PERSONAL DATA
            <ol type="1">
              <li>
                We may retain your personal data for as long as it is necessary
                to fulfil the purpose for which it was collected, or as required
                or permitted by applicable laws.
              </li>
              <li>
                We will cease to retain your personal data, or remove the means
                by which the data can be associated with you, as soon as it is
                reasonable to assume that such retention no longer serves the
                purpose for which the personal data was collected, and is no
                longer necessary for legal or business purposes.
              </li>
            </ol>
          </li>
          <li>
            DATA PROTECTION OFFICER
            <p>
              You may contact our Data Protection Officer if you have any
              enquiries or feedback on our personal data protection policies or
              procedures, or if you wish to make any request, in the following
              manner:
            </p>
            <div>
              Address: 2 Science Park Drive, #01-03, Ascent, Singapore 118222
            </div>
            <div>
              <p>Contact No.: +65 6250 2337</p>
              <p>Email Address: dpo@eco-business.com</p>
            </div>
          </li>
          <li>
            GOVERNING LAW
            <p>
              This Policy, and any dispute relating to this Policy or the
              Website, are governed by and must be interpreted in accordance
              with the law of Singapore.  Each party submits to the
              non-exclusive jurisdiction of the Courts of Singapore in
              relation to any dispute connected with this Policy or the Website.
            </p>
          </li>
          <li>
            EFFECT OF POLICY AND CHANGES TO POLICY
            <ol type="1">
              <li>
                This Policy applies in conjunction with any other notices,
                contractual clauses and consent clauses that apply in relation
                to the collection, use and disclosure of your personal data by
                us.
              </li>
              <li>
                We may revise this Policy from time to time without any prior
                notice. You may determine if any such revision has taken place
                by referring to the date on which this Policy was last updated.
                Your continued use of our services constitutes your
                acknowledgement and acceptance of such changes.
              </li>
            </ol>
            <div>
              <p>
                Effective Date: <span>6 September 2021</span>
              </p>
              <p>
                Last Updated: <span>6 September 2021</span>
              </p>
            </div>
          </li>
        </ol>
      </div>
    );
  };
}

export default PrivacyView;
