import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { sendRequest } from '../helpers/RequestDispatcher.js';
import { triggerEvent } from '../helpers/global.js';

import TextInput from './input/TextInput';
import SelectInput from './input/SelectInput';

import '../sass/components/EditSolutionsView.scss';

const MAX_NAME = 100;
const MAX_DESCRIPTION = 300;

const mapStoreToProps = (store) => ({
  user: store.data.user,
});

class EditSolutionsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.getEmptyItem(),
      solutions: [],
      categories: [],
      subcategories: [],
    };
  }

  INIT_DATA = [
    { url: 'common_data/categories', object: 'categories' },
    { url: 'common_data/subcategories', object: 'subcategories' },
    { url: 'solutions', object: 'solutions', data: { profile_id: this.props.organizationId }, condition: this.props.organizationId},
  ];

  componentDidMount = () => {
    this.INIT_DATA.forEach(item => this.getData(item.url, item.object, item.data, item.condition));
  }

  getData = (url, object, data = null, condition = true) => {
    if (condition === null || !!condition) {
      sendRequest({
        method: url,
        data,
        type: 'GET',
        success: (data) => {
          this.setState({[object]: data});
        },
        error: (data) => {
        }
      });
    }
  }

  getEmptyItem = () => {
    return ({
      name: '',
      description: '',
      category_id: null,
      subcategory_id: null,
    })
  }

  onSave = () => {
    if (this.props.onSave) {
      this.props.onSave();
    }
  }

  saveItem = (item) => {
    const { name, description, subcategory_id } = item;
    sendRequest({
      method: `solutions/${item.id}`,
      type: 'PUT',
      data: {
        name,
        description,
        subcategory_id,
        profile_id: this.props.organizationId,
      },
      success: (data) => {
        if (data) {
          this.setState({
            solutions: [
              ...this.state.solutions,
              {
                id: data.id,
                ...data,
              }
            ],
          });
          triggerEvent('showSnackbar', [{text: 'Item saved!', type: 'success'}]);
        }
      },
      error: (data) => {
        if (data.errors) {
          triggerEvent('showSnackbar', [{text: data.errors, type: 'error'}]);
        }
      }
    });
  }

  updateItem = (id, key, value) => {
    if (key === 'name') {
      value = (value || '').substr(0, MAX_NAME);
    } else if (key === 'description') {
      value = (value || '').substr(0, MAX_DESCRIPTION);
    }
    if (id) {
      this.setState({solutions:
        this.state.solutions.map(i => i.id === id ? {
          ...i,
          [key]: value,
        } : i)
      });
    } else {
      this.setState({item: {
        ...this.state.item,
        [key]: value,
      }});
    }
  }

  deleteItem = (id) => {
    sendRequest({
      method: `solutions/${id}`,
      type: 'DELETE',
      data: {
        profile_id: this.props.organizationId,
      },
      success: (data) => {
        this.setState({solutions:
          this.state.solutions.filter(i => i.id !== id)
        });
      },
      error: (data) => {
      }
    });
  }

  createItem = () => {
    const { name, description, subcategory_id } = this.state.item;
    if (!this.props.organizationId) {
      triggerEvent('showSnackbar', [{text: 'Please complete General Information first.', type: 'error'}]);
      return;
    }
    sendRequest({
      method: 'solutions',
      type: 'POST',
      data: {
        name,
        description,
        subcategory_id,
        profile_id: this.props.organizationId,
      },
      success: (data) => {
        triggerEvent('showSnackbar', [{text: 'Item saved!', type: 'success'}]);
        this.setState({
          solutions: [
            ...this.state.solutions,
            {
              id: data.id,
              ...data,
            }
          ],
          item: this.getEmptyItem(),
        });
      },
      error: (data) => {
        if (data.errors) {
          triggerEvent('showSnackbar', [{text: data.errors, type: 'error'}]);
        }
      }
    });
  }

  renderSolution = (item, create = false) => {
    const complete = item.name && item.category_id && item.subcategory_id && item.description;
    const { categories, subcategories } = this.state;
    return (
      <div key={item.id} className='solutionItem'>
        <div className='solutionContent'>
          <div className='solutionColumn'>
            <div className='itemTitle'>Solution Name</div>
            <div className='characterCounter'>{(item.name || '').length} / {MAX_NAME}</div>
            <TextInput
              value={item.name}
              onChange={value => this.updateItem(item.id, 'name', value)}
            />
            <div className='itemTitle'>Category</div>
            <SelectInput
              options={categories}
              value={item.category_id}
              onChange={value => this.updateItem(item.id, 'category_id', value)}
              placeholder='Select a Category'
            />
            <SelectInput
              options={subcategories.filter((subcategory) => subcategory.category_id === item.category_id)}
              value={item.subcategory_id}
              onChange={value => this.updateItem(item.id, 'subcategory_id', value)}
              placeholder='Select a Sub-Category'
              disabled={!item.category_id}
            />
          </div>
          <div className='solutionColumn'>
            <div className='itemTitle'>Solution Description</div>
            <div className='characterCounter'>{(item.description || '').length} / {MAX_DESCRIPTION}</div>
            <TextInput
              type='textarea'
              value={item.description}
              onChange={value => this.updateItem(item.id, 'description', value)}
            />
          </div>
        </div>
        <div className='solutionControls'>
          {create ? <>
            <button
              disabled={!complete}
              onClick={this.createItem}
            >Create</button>
          </> : <>
            <button
              disabled={!complete}
              onClick={() => this.saveItem(item)}
            >Save</button>
            <button
              className='delete'
              onClick={() => this.deleteItem(item.id)}
            >Delete Solution</button>
          </>}
        </div>
      </div>
    )
  }

  render = () => {
    return (
      <div
        className={classnames('editSolutionsView', {
          'wizard': this.props.wizard,
        })}
      >
        <div className='solutionsContainer'>
          {this.state.solutions.map(item => this.renderSolution(item))}
          {this.renderSolution(this.state.item, true)}
        </div>
        <div className='buttonBlock'>
          <button onClick={this.onSave}>
            {this.props.wizard ? 'Continue' : 'Save'}
          </button>
        </div>
      </div>
    )
  }

}

export default connect(mapStoreToProps)(withRouter(EditSolutionsView));
