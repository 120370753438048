import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import '../sass/components/FooterView.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
});

class FooterView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleLinkClick = (link, external = false) => {
    if (external) {
      window.open(link, '_blank');
    } else {
      this.props.history.push(link);
    }
  }

  render = () => {
    return (
      <div className='footerView'>
        <div className='leftLinks'>
          <div className='footerLink sdg' onClick={() => this.handleLinkClick('/')} />
          <div className='footerLink twitter' onClick={() => this.handleLinkClick('https://twitter.com/ecobusinesscom', true)} />
          <div className='footerLink facebook' onClick={() => this.handleLinkClick('https://facebook.com/EcoBusinessMedia', true)} />
          <div className='footerLink instagram' onClick={() => this.handleLinkClick('https://instagram.com/ecobusinesscom', true)} />
        </div>
        <div className='copyright'>
          © Eco-Business 2009 - {(new Date()).getFullYear()}. All rights reserved.
        </div>
        <div className='rightLinks'>
        <div className='footerLink' >  <a href='https://faq.thesdg.co/' target="_blank" className='faqLink'>FAQ</a></div>
          <div className='footerLink' onClick={() => this.handleLinkClick('/privacy')}>Privacy Policy</div>
          <div className='footerLink' onClick={() => this.handleLinkClick('/terms')}>Terms of Use</div>
        </div>
      </div>
    )
  }

}

export default connect(mapStoreToProps)(withRouter(FooterView));
