import React from 'react';
import dayjs from 'dayjs';
import classnames from 'classnames';
import { connect } from 'react-redux';
import ActionCable from 'actioncable';
import { withRouter } from 'react-router-dom';
import { MdSend } from 'react-icons/md';
import { AiOutlineUser } from 'react-icons/ai';

import { WSUrl, triggerEvent } from '../helpers/global.js';
import { sendRequest } from '../helpers/RequestDispatcher.js';

import '../sass/components/ChatView.scss';

const mapStoreToProps = (store) => {
  return {
    user: store.data.user,
    isMobile: store.setup.isMobile,
    businessProfile: store.data.businessProfile,
  };
};

class ChatView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      partner: null,
      partnerType: null,
      messages: [],
      message: '',
      file: null,
      search: '',
      matches: [],
      currentMatch: 0,
      messagePending: false,
      showChat: false,
      chatParticipants: [],
      errors: null,
      messagesLimit: props.messagesLimit,
    };
    this.cable = null;
    this.subscription = null;
    this.fileInput = null;
    this.pingTimer = null;
  }

  paramId = () => {
    return this.props.embedded ? this.props.id : this.props.match.params.id;
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.embedded && this.props.id !== prevProps.id) {
      this.disconnectChannel();
      this.connectToChat();
    }
  };

  componentDidMount = () => {
    this.connectToChat();
  };

  componentWillUnmount = () => {
    this.disconnectChannel();
  };

  scrollMessages = () => {
    const messages = document.querySelector('.messages');
    messages.scrollTo({
      top: messages.scrollHeight,
      behavior: 'smooth',
    });
  };

  connectToChat = () => {
    triggerEvent('addLoad');
    sendRequest({
      type: 'GET',
      method: `chats/${this.paramId()}`,
      success: (data) => {
        triggerEvent('removeLoad');
        this.connectChannel();

        const { partner, partnerType } = this.chatPartner(
          data.chat_participants
        );
        this.setState(
          {
            messages: data.messages,
            chatParticipants: data.chat_participants,
            partner: partner,
            partnerType: partnerType,
            errors: null,
            file: null,
            message: '',
          },
          () => {
            this.scrollMessages();
          }
        );
      },
      error: (data) => {
        triggerEvent('removeLoad');
      },
    });
  };

  disconnectChannel = () => {
    clearInterval(this.pingTimer);
    this.pingTimer = null;
    if (this.cable) {
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.subscription = null;
      }
      this.cable.disconnect();
      this.cable = null;
    }
  };

  connectChannel = () => {
    this.disconnectChannel();
    this.cable = ActionCable.createConsumer(WSUrl());
    this.subscription = this.cable.subscriptions.create(
      {
        channel: 'ChatChannel',
        id: this.paramId(),
      },
      {
        connected: () => {
          this.pingTimer = console.log(
            `Connected to chat with ${this.paramId()}`
          );
        },
        disconnected: () => {
          clearInterval(this.pingTimer);
          this.pingTimer = null;
        },
        received: (data) => {
          if (data.action === 'create') {
            if (this.props.onMessageAdded) {
              this.props.onMessageAdded(data.message);
            }
            this.setState(
              {
                messages: [...this.state.messages, data.message],
              },
              this.scrollMessages
            );
          } else if (data.action === 'delete') {
            this.setState({
              messages: this.state.messages.filter(
                (m) => data.message_ids.indexOf(m.id) < 0
              ),
            });
          }
        },
        ping_online: () => {
          return this.subscription.perform('ping_online', {
            chat_id: this.props.id,
            user_id: this.props.user.id,
          });
        },
      }
    );
  };

  ownProfile = () => {
    const { businessProfile, user } = this.props;
    return businessProfile ? businessProfile : user;
  };

  ownProfileType = () => {
    const { businessProfile } = this.props;
    return businessProfile ? 'business_profile' : 'user';
  };

  ownProfileTypeId = () => {
    const { businessProfile } = this.props;
    return businessProfile ? 'business_profile_id' : 'user_id';
  };

  chatPartner = (chat_participants) => {
    const ownProfileType = this.ownProfileType();
    const ownProfile = this.ownProfile();
    const chatParticipant = chat_participants.find(
      (participant) => participant[ownProfileType]?.id !== ownProfile.id
    );
    const type = chatParticipant.hasOwnProperty('user')
      ? 'user'
      : 'business_profile';
    const partner = chatParticipant[type];
    return {
      partner: partner,
      partnerType: type,
    };
  };

  sendMessage = () => {
    if (!this.state.message || this.state.messagePending) {
      return;
    }
    this.setState({ messagePending: true });
    sendRequest({
      type: 'POST',
      method: `chats/${this.paramId()}/message`,
      data: {
        business_profile_id: this.props.businessProfile?.id,
        content: this.state.message,
      },
      noLoad: true,
      success: (data) => {
        this.setState({ message: '', messagePending: false });
      },
      error: (data) => {
        this.setState({ messagePending: false, errors: data });
      },
    });
  };

  sendFile = (e) => {
    if (!this.state.file || this.state.messagePending) {
      return;
    }
    let formData = new FormData();
    if (this.props.businessProfile) {
      formData.append('business_profile_id', this.props.businessProfile.id);
    }
    formData.append('file', this.state.file);
    this.setState({ messagePending: true });
    sendRequest({
      type: 'POST',
      method: `chats/${this.paramId()}/message_file`,
      formData,
      noLoad: true,
      success: (data) => {
        this.setState({ file: null, messagePending: false });
        this.fileInput.value = '';
      },
      error: (data) => {
        this.setState({ messagePending: false, errors: data });
      },
    });
  };

  onSearchChange = (search) => {
    const matches = search
      ? this.state.messages
          .filter(
            (m) =>
              (m.content &&
                m.content.toLowerCase().match(search.toLowerCase())) ||
              (m.file &&
                m.file.name &&
                m.file.name.toLowerCase().match(search.toLowerCase()))
          )
          .map((m) => m.id)
      : [];
    this.setState({ search, matches, currentMatch: 0 });
    if (matches.length) {
      setTimeout(() => {
        this.scrollIntoMessage(matches[0]);
      }, 75);
    }
  };

  switchMatch = (change) => {
    if (!this.state.matches.length) {
      return;
    }
    let currentMatch =
      (this.state.currentMatch + change) % this.state.matches.length;
    if (currentMatch < 0) {
      currentMatch += this.state.matches.length;
    }
    this.setState({ currentMatch });
    this.scrollIntoMessage(this.state.matches[currentMatch]);
  };

  scrollIntoMessage = (id) => {
    const elem = document.querySelector(`[data-id="${id}"]`);
    if (elem) {
      elem.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  attachFile = () => {
    if (this.state.file) {
      this.setState({ file: null });
      this.fileInput.value = '';
    }
    this.fileInput.click();
  };

  renderChatMessageBulk = (bulk, index) => {
    const ownProfileTypeId = this.ownProfileTypeId();
    const ownProfile = this.ownProfile();
    const owned =
      ownProfile.id === bulk.authorId && ownProfileTypeId === bulk.authorType;
    const lastTime = bulk.messages[bulk.messages.length - 1].created_at;
    return (
      <div
        key={index}
        className={classnames({
          messageBulk: true,
          owned: owned,
        })}
      >
        {bulk.messages.map(this.renderChatMessage)}
        <div className="bulkTime">{dayjs.unix(lastTime).fromNow()}</div>
      </div>
    );
  };

  renderChatMessage = (message, index) => {
    const ownProfileTypeId = this.ownProfileTypeId();
    const ownProfile = this.ownProfile();
    const messageType = message.user_id ? 'user_id' : 'business_profile_id';
    const owned =
      ownProfileTypeId === messageType &&
      ownProfile.id === message[messageType];
    const author = owned ? ownProfile : this.state.partner;
    const text = message.content
      ? message.content.replace(/\n/g, '<br/>')
      : null;
    const file = message.file || null;

    return (
      <div key={index} data-id={message.id} className="messageContainer">
        <img
          src={author.image ?? author.image_url ?? '/images/icons/profile.svg'}
          alt="Author"
          className="messageAvatar"
        />
        <div
          className={classnames({
            messageItem: true,
            owned: owned,
            searched: this.state.matches.indexOf(message.id) > -1,
            focused: this.state.matches[this.state.currentMatch] === message.id,
          })}
        >
          {text ? (
            <div
              className="messageContent"
              dangerouslySetInnerHTML={{ __html: text }}
            />
          ) : null}
          {file ? (
            <div
              className="fileLink"
              onClick={() => window.open(file.url, '_blank')}
            >
              {file.name}
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  renderUnValidMessageInput = () => {
    return (
      <div className="messageInputContainer">
        <div className="messageWrap">
          <div className="attachFiles limitReached">
            <img
              src="http://thesdg.herokuapp.com//static/media/image.de12ef61.svg"
              alt="Attach files"
            />
          </div>
          <div className="fakeTextArea">
            You have ran out of free messages to send.
            <div
              className="upgradePlan"
              onClick={() => this.props.history.push('/subscribe')}
            >
              Upgrade to get unlimited chat messages
            </div>
          </div>
          <div className="sendButton disabled limitReached">
            <MdSend size="18" />
          </div>
        </div>
      </div>
    );
  };

  renderMessageInput = () => {
    return (
      <div className="messageInputContainer">
        <div className="messageWrap">
          <div className="attachFiles" onClick={this.attachFile}>
            <img
              src="http://thesdg.herokuapp.com//static/media/image.de12ef61.svg"
              alt="Attach file"
            />
          </div>
          {this.state.file ? (
            <div className="fileInfo">{this.state.file.name}</div>
          ) : (
            <textarea
              value={this.state.message}
              onChange={(e) => this.setState({ message: e.target.value })}
              disabled={this.state.messagePending}
              placeholder="Start a new message"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  e.stopPropagation();
                  if (e.ctrlKey || e.shiftKey) {
                    this.setState({ message: this.state.message + '\n' });
                  } else {
                    this.sendMessage();
                  }
                }
              }}
            />
          )}
          <input
            type="file"
            onChange={(e) => this.setState({ file: e.target.files[0] })}
            ref={(input) => (this.fileInput = input)}
          />
          {this.state.file && !this.state.messagePending ? (
            <div
              className="removeButton"
              onClick={() => {
                this.setState({ file: null });
                this.fileInput.value = '';
              }}
            >
              <span className="material-icons">close</span>
            </div>
          ) : null}
          {this.state.messagePending ? (
            <div className="pendingContainer">
              <div className="pendingIndicator" />
            </div>
          ) : (
            <>
              <div
                className={classnames({
                  sendButton: true,
                  disabled: !this.state.message && !this.state.file,
                })}
                onClick={this.state.file ? this.sendFile : this.sendMessage}
              >
                <MdSend size="18" />
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  messageInput = () => {
    const { errors, messagesLimit } = this.state;
    const limitReached = messagesLimit !== null && messagesLimit <= 0;
    return limitReached || errors?.error_message
      ? this.renderUnValidMessageInput()
      : this.renderMessageInput();
  };

  renderSearch = () => {
    const { search, matches, currentMatch } = this.state;
    return (
      <div className="searchContainer">
        {this.props.embedded && this.props.onClose ? (
          <div className="backIcon" onClick={this.props.onClose}>
            <span className="material-icons">keyboard_backspace</span>
          </div>
        ) : null}
        <div className="searchIcon">
          <span className="material-icons">search</span>
        </div>
        <input
          type="text"
          value={search}
          onChange={(e) => this.onSearchChange(e.target.value)}
        />
        <div className="searchControls">
          <div
            className={classnames({
              searchSwitch: true,
              disabled: !matches.length,
            })}
            onClick={() => this.switchMatch(-1)}
          >
            <span className="material-icons">arrow_left</span>
          </div>
          <div
            className={classnames({
              searchSwitch: true,
              disabled: !matches.length,
            })}
            onClick={() => this.switchMatch(1)}
          >
            <span className="material-icons">arrow_right</span>
          </div>
        </div>
        <div className="resultLabel">
          {matches.length
            ? `${currentMatch + 1} of ${matches.length}`
            : 'No results'}
        </div>
      </div>
    );
  };

  renderChat = () => {
    const partner = this.state.partner;
    const messages = this.state.messages || [];
    const messagesLimit = this.state.messagesLimit;
    let bulks = [];
    messages.forEach((message) => {
      const messageAuthorType = message.user_id
        ? 'user_id'
        : 'business_profile_id';
      const messageAuthorId = message[messageAuthorType];
      const lastBulk = bulks[bulks.length - 1];
      if (
        lastBulk &&
        lastBulk.authorId === messageAuthorId &&
        lastBulk.authorType === messageAuthorType &&
        message.created_at - lastBulk.firstTime < 3600
      ) {
        lastBulk.messages.push(message);
      } else {
        bulks.push({
          authorType: messageAuthorType,
          authorId: messageAuthorId,
          firstTime: message.created_at,
          messages: [message],
        });
      }
    });
    return (
      <div className="chatContainer">
        <div className="partnerInfo">
          {this.props.embedded && this.props.onClose ? (
            <div className="backIcon" onClick={this.props.onClose}>
              <span className="material-icons">keyboard_backspace</span>
            </div>
          ) : null}
          {partner ? (
            <>
              {partner.image_url ? (
                <div
                  className="partnerImage"
                  style={{ backgroundImage: `url(${partner.image_url})` }}
                />
              ) : (
                <div className="partnerImage">
                  <AiOutlineUser size="40" />
                </div>
              )}
              <div className="partnerName">
                <span
                  onClick={() =>
                    this.props.history.push(`/profile/${partner.id}`)
                  }
                >
                  {partner.name}
                </span>
                {partner.subscribed ? <div className="subscribed" /> : null}
              </div>
            </>
          ) : null}
        </div>
        {this.props.isMobile && !this.ownProfile().subscribed ? (
          <div className="messageLimit">
            <div>{`You can only send ${messagesLimit} / 10 free messages left.`}</div>
            <div
              className="upgradePlan"
              onClick={() => this.props.history.push('/subscribe')}
            >
              Upgrade to get unlimited chat messages
            </div>
          </div>
        ) : null}
        {/*this.renderSearch()*/}
        <div className="messages customScrollBar">
          {bulks.map(this.renderChatMessageBulk)}
        </div>
        {this.messageInput()}
      </div>
    );
  };

  render = () => {
    return (
      <div
        className={classnames({
          chatView: true,
          embedded: this.props.embedded,
        })}
      >
        {this.renderChat()}
      </div>
    );
  };
}

export default connect(mapStoreToProps)(withRouter(ChatView));
