import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import update from 'immutability-helper';
import { Helmet } from 'react-helmet';

import FeedHeader from './FeedHeader';
import Post from './Post';
import Sidebar from './Sidebar';
import PostForm from './PostForm';
import PostFormPopup from '../common/PostFormPopup';

import ContentPlaceholder from '../common/ContentPlaceholder';

import { triggerEvent } from '../../helpers/global.js';
import { sendRequest } from '../../helpers/RequestDispatcher.js';

import { feed } from '../../data/feed_data';

import '../../sass/components/feed/FeedView.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
});

const ITEMS_PER_LOAD = 2;

class FeedView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      feed: {},
      goals: [],
      posts: [],
      loadNumber: 0,
      noMore: false,
      loaded: false,
      data: this.defaultData(),
    };
  }

  componentDidMount = () => {
    const { key } = this.props.match.params;
    sendRequest({
      method: 'common_data/goals',
      type: 'GET',
      success: (data) => {
        this.setState({ ['goals']: data });
      },
      error: (data) => {},
    });
    sendRequest({
      type: 'GET',
      method: `goals/${key}`,
      success: (data) => {
        if (data) {
          this.setState({ feed: data });
        }
      },
      error: (data) => {},
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.goals !== this.state.goals) {
      const { key } = this.props.match.params;
      const goalId = this.state.goals.find((i) => i.key === key)?.id;
      this.setState({ data: { ...this.state.data, ['goalIds']: [goalId] } });
      sendRequest({
        type: 'GET',
        method: 'posts',
        data: {
          limit: ITEMS_PER_LOAD,
          goal_ids: [goalId],
        },
        success: (data) => {
          if (data) {
            this.setState({
              posts: data,
              loadNumber: 1,
              noMore: data.length < ITEMS_PER_LOAD,
              loaded: true,
            });
          }
        },
        error: (data) => {},
      });
    }
  };

  defaultData = (goals) => {
    const { key } = this.props.match.params;
    const goalId = goals ? goals.find((i) => i.key === key)?.id : null;
    return {
      authorTitle: '',
      postId: null,
      file: null,
      goalIds: [goalId],
      content: '',
      title: '',
      link: '',
    };
  };

  loadMore = () => {
    const { key } = this.props.match.params;
    const goalId = this.state.goals
      ? this.state.goals.find((i) => i.key === key)?.id
      : null;
    sendRequest({
      type: 'GET',
      method: 'posts',
      data: {
        goal_ids: [goalId],
        offset: this.state.loadNumber * ITEMS_PER_LOAD,
        limit: ITEMS_PER_LOAD,
      },
      success: (data) => {
        if (data.length === 0) {
          triggerEvent('showSnackbar', [{ text: 'Sorry, no more posts.' }]);
          this.setState({
            noMore: true,
          });
        } else {
          this.setState((prevState) => ({
            posts: prevState.posts.concat(data),
            loadNumber: prevState.loadNumber + 1,
            noMore: data.length < ITEMS_PER_LOAD,
          }));
        }
      },
      error: (data) => {},
    });
  };

  handleDataChange = (key, value) => {
    const { data } = this.state;
    this.setState({ data: { ...data, [key]: value } });
  };

  handleCreatedPost = (data) => {
    const { key } = this.props.match.params;
    const goalId = this.state.goals.find((i) => i.key === key)?.id;
    if (data && data.goal_ids.includes(Number(goalId))) {
      this.setState((prevState) => ({
        posts: [data, ...this.state.posts],
      }));
    }
  };

  handlePostUpdate = (data) => {
    const postIds = this.state.posts.map((post) => post.id);
    if (data && data.goal_ids.includes(this.state.feed.id)) {
      this.setState({
        posts: update(this.state.posts, {
          [postIds.indexOf(data.id)]: { $set: data },
        }),
      });
    } else if (data) {
      this.setState({
        posts: this.state.posts.filter((p) => p.id !== data.id),
      });
    }
  };

  handleEditClick = (post) => {
    this.setState({
      showPopupForm: true,
      data: {
        authorTitle: post.author_title,
        file: post.image_url,
        postId: post.id,
        goalIds: post.goal_ids,
        content: post.content,
        title: post.title,
        link: post.link,
      },
    });
  };

  handleDeleteClick = (post) => {
    triggerEvent('showConfirmation', [
      {
        title: 'Are you sure you want to remove this post?',
        overlayBlock: true,
        callback: (e) => this.removePost(e, post.id),
      },
    ]);
  };

  postFormPopupCallback = (data) => {
    const postIds = this.state.posts.map((post) => post.id);
    if (data && postIds.includes(data.id)) {
      this.handlePostUpdate(data);
    } else if (data) {
      this.handleCreatedPost(data);
    }
    this.setState({
      showPopupForm: false,
      data: this.defaultData(this.state.goals),
    });
  };

  removePost = (e, id) => {
    if (!e) return;
    let method = `posts/${id}`;
    let author_id = this.props.user?.id;
    if (this.props.businessProfile) {
      author_id = this.props.businessProfile.id;
      method = `business_profiles_managing/${author_id}/posts/${id}`;
    }
    sendRequest({
      type: 'DELETE',
      method: method,
      success: (data) => {
        triggerEvent('showSnackbar', [
          { text: 'Post deleted successfully', type: 'success' },
        ]);
        this.setState({
          posts: this.state.posts.filter((p) => p.id !== id),
        });
      },
      error: (data) => {},
    });
  };

  renderPosts = () => {
    return (
      <>
        {this.state.posts &&
          this.state.posts.map((post) => (
            <Post
              key={post.id}
              post={post}
              user={this.props.user}
              author={post.author}
              likeCount={post.likes_count}
              publishDateTime={post.created_at}
              article={post.author_title}
              isLiked={post.liked}
              onEditClick={() => this.handleEditClick(post)}
              onDeleteClick={() => this.handleDeleteClick(post)}
            />
          ))}
        {this.state.noMore ? null : (
          <div className="loadMore" onClick={this.loadMore}>
            <p>Load more posts...</p>
          </div>
        )}
      </>
    );
  };

  renderTimeline = () => (
    <div className="feedTimeline">
      {this.props.user ? (
        <PostForm
          onStart={() => this.setState({ showPopupForm: true })}
          onDataChange={this.handleDataChange}
          file={this.state.data.file}
        />
      ) : null}
      {this.state.loaded
        ? this.renderPosts()
        : [...Array(2)].map((element, index) => (
            <div key={index} className="postContentPlaceholder">
              <ContentPlaceholder type="post" />
            </div>
          ))}
    </div>
  );

  render = () => (
    <>
      <Helmet>
        <title>The SDG Co</title>
        <meta name="description" content="The SDG Co" />
        <meta name="keywords" content="social" />
        <meta property="og:url" content="https://thesdg.co" />
        <meta property="og:site_name" content="The SDG Co" />
        <meta property="og:title" content="The SDG Co" />
        <meta property="og:image" content="https://thesdg.co/thesdg.png" />
        <meta property="og:description" content="The SDG Co" />
        <meta property="og:type" content="website" />
        <meta name="image" content="https://thesdg.co/thesdg.png" />
      </Helmet>
      <FeedHeader
        isFollowing={this.state.feed?.followed}
        icon={feed.icon}
        feed={this.state.feed.name || ''}
        count={this.state.feed.followers_count || 0}
        feedKey={this.state.feed?.key}
        user={this.props.user}
      />
      <div className="feed">
        {this.renderTimeline()}
        {this.props.user ? (
          <PostFormPopup
            authorTitle={this.state.data.authorTitle}
            goalIds={this.state.data.goalIds}
            show={this.state.showPopupForm}
            file={this.state.data.file}
            callback={this.postFormPopupCallback}
            postId={this.state.data.postId}
            content={this.state.data.content}
            title={this.state.data.title}
            link={this.state.data.link}
          />
        ) : null}
        <Sidebar />
      </div>
    </>
  );
}

export default connect(mapStoreToProps)(withRouter(FeedView));
