import React from 'react';
import { Helmet } from 'react-helmet';

function HeaderMeta() {
  return (
    <Helmet>
      <title>The SDG Co</title>
      <meta name="description" content="The SDG Co" />
      <meta name="keywords" content="social" />
      <meta property="og:url" content="https://thesdg.co" />
      <meta property="og:site_name" content="The SDG Co" />
      <meta property="og:title" content="The SDG Co" />
      <meta property="og:image" content="https://thesdg.co/thesdg.png" />
      <meta property="og:description" content="The SDG Co" />
      <meta property="og:type" content="website" />
      <meta name="image" content="https://thesdg.co/thesdg.png" />
    </Helmet>
  );
}

export default HeaderMeta;
