import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import CheckboxInput from '../components/input/CheckboxInput';
import { Helmet } from 'react-helmet';
import TextInput from '../components/input/TextInput';
import SelectInput from './input/SelectInput';
import { sendRequest } from '../helpers/RequestDispatcher.js';
import Avatar from './common/Avatar';
import { triggerEvent } from '../helpers/global.js';
import ProfileCard from './common/ProfileCard';
import GoalCard from './common/GoalCard';
import ReCAPTCHA from "react-google-recaptcha";

import '../sass/components/ClaimProfileView.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
  isMobile: store.setup.isMobile,
});


const INIT_DATA = [
  {
    url: 'posts',
    object: 'posts',
    data: { limit: 3, trending: true },
    callback: (ctx, data) => ctx.afterPostsRequest(data),
  },
  {
    url: 'users',
    object: 'users',
    data: { limit: 4, order: 'trending' },
    callback: (ctx, data) => ctx.afterUsersRequest(data),
  },
  { url: 'common_data/goals', object: 'goals' },
  { url: 'common_data/objectives', object: 'objectives' },
];

class ClaimProfileView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // data: {},
      users: [],
      posts: [],
      goals: [],
      objectives: [],
      usersLoaded: false,
      postsLoaded: false,
      adsTagLoaded: false,
      disabledsubmit: true,
      businessProfile: [],
      data: {
        email: '',
        name: '',
        wish_to: '',
        detail: '',
        founder: false,
        company_name: '',
        company_url: '',
        company_id: ''
      },
      errors: {},
    };
  }

  renderInput = (key, type = 'text', placeholder = '') => {
    return (
      <TextInput
        type={type}
        value={this.state.data[key]}
        placeholder={placeholder}
        error={this.state.errors[key]}
        onChange={(value) =>
          this.setState({
            data: {
              ...this.state.data,
              [key]: value,
            },
          })
        }
      />
    );
  };

  saveClaimProfile = (key)  => {
    const { email, name, wish_to, detail, founder } = this.state.data;
    const business_profile_id = this.props.history.location.state?.businessProfile?.id
    const company_id = this.props.history.location.state?.businessProfile?.id
    const company_name = this.props.history.location.state?.businessProfile?.name
    const company_url = this.props.history.location.state?.company_url
    let errors = {};
    if (!email) {
      errors.email = 'Please enter Email';
    }
    if (!name) {
      errors.name = 'Please enter Name';
    }
    if (!wish_to) {
      errors.wish_to = 'Please Select Wish To';
    }
    if (!detail) {
      errors.detail = 'Please enter Detail';
    }
    this.setState({ errors });
    if (Object.keys(errors).length > 0) {
      return;
    }
    triggerEvent('addLoad');
    sendRequest({
      method: 'claim_profiles',
      type: 'POST',
      data: {
        email,
        name,
        wish_to,
        detail,
        founder,
        business_profile_id,
        company_name,
        company_url,
        company_id,

      },
      noLoad: true,
      success: (data) => {
        triggerEvent('removeLoad');
        triggerEvent('showSnackbar', [
          { text: "Your Claim/Remove Profile request has been submitted.", type: 'success' },
        ]);
        this.props.history.push(`/`);
      },
      error: (data) => {
        triggerEvent('removeLoad');

      },
    });
  };
 

//  onChange(value) {
//   console.log("Captcha value:", value);
// }
 
  
  renderActiveUsers = () => {
    return (
      <div className="registerForm">
        <div className="formContent">
          <div className="formTitle"></div>
          <div className='wish_div'>
          <label className='labelText'>I wish to</label>
          <SelectInput
              options={[
                { id: 'claim', name: 'Claim this profile' },
                { id: 'remove', name: 'Remove this profile' }
              ]}
              value={this.state.data.wish_to}
              key='wish_to'
              error={this.state.errors.wish_to}
              onChange={value =>  this.setState({
                data: {
                  ...this.state.data,
                  wish_to: value,
                },
              })}
              placeholder='Select Wish to'
            />
            </div>
          <label className='labelText'>Full Name</label>
          {this.renderInput('name', '', 'Full Name')}
          <label className='labelText'>Email Address</label>
          {this.renderInput('email', 'email', 'yourname@email.com')}
          <label className='labelText'>Details</label>
          {this.renderInput('detail', 'textarea', 'Please include references that can prove you are part of the company listed above. This can come in the form of linkedin URLs, a google drive link with supporting images or any other kind of documentation proving so. If you are requesting to remove this profile, please also include your reasons for wanting this profile removed.')}
          <br></br>
          <CheckboxInput key="founder" small={true} value={this.state.data.founder}
          label="I hereby certify that I’m a part of the leadership team, who can speak on behalf of this company."
          onChange={value =>  this.setState({
            data: {
              ...this.state.data,
              founder: value,
            },
            disabledsubmit: !value
          })}
          />
          <br></br>
          <ReCAPTCHA
    sitekey="6Ld1owEfAAAAAB4PPXyd5Jxmfa1szRu6qEb-dCh6"
    // onChange={onChange}
  />
          <br></br>
          <button className='submitClaimBtn' disabled={this.state.disabledsubmit} onClick={() => this.saveClaimProfile(this.state.data)}>
            Submit
          </button>

        </div>
      </div>
    );
  };


  render = () => {
    const { posts, goals, businessProfile } = this.state;

const business = this.props.history.location.state?.businessProfile
    return (
      <div className="browseView">
        <Helmet>
          <title>The SDG Co</title>
          <meta name="description" content="The SDG Co" />
          <meta name="keywords" content="social" />
          <meta property="og:url" content="https://thesdg.co" />
          <meta property="og:site_name" content="The SDG Co" />
          <meta property="og:title" content="The SDG Co" />
          <meta property="og:image" content="https://thesdg.co/thesdg.png" />
          <meta property="og:description" content="The SDG Co" />
          <meta property="og:type" content="website" />
          <meta name="image" content="https://thesdg.co/thesdg.png" />
        </Helmet>
        <section className="sdgGoals">
          <div className="profileClaim">
             <Avatar
                imageUrl={business.cover_photo}
                // imageUrl="https://thesdg.co/thesdg.png"
                username={business.name}
                className={
                  ('profileImage')
                }
                big
              />
             <div className="authorNameTime">
               <div className="authorName">
                 {business.name}
              </div>
              </div>
          </div>
          <div className="">
          {this.renderActiveUsers()}
          
          </div>
        </section>


      </div>
    );
  };
}

export default connect(mapStoreToProps)(withRouter(ClaimProfileView));
